'use strict';
(function(){
var module = angular.module('xp-element-spire-concept-assessment', ['client.services', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'client.directives', 'xp-element-spire-common']);
module.factory('ClassConceptAssessmentScoresService', ['$resource', function($resource){
  var url = "/main/v1/" + "spire/class/lesson/CAScores/:classId/:level/:lessonId";
  return $resource(url, {classId: '@classId', level: '@level', lessonId: '@lessonId'},
      {
        get: {method: 'GET', isArray: true, params: {classId: '@classId', level: '@level', lessonId: '@lessonId'}}
      });
}]);

module.controller('clientSpireConceptAssessmentElementCtrl',
    ['$scope', 'ElementsRestService', 'ElementsErrorService', 'spire', 'ClassConceptAssessmentScoresService', 'IconOverlays',
     'ScoreService',
function ($scope, ElementsRestService, ElementsErrorService, spire, ClassConceptAssessmentScoresService, IconOverlays, ScoreService) {
  spire.configureScope($scope, parseElement, displayUserResponses, isResponseModified, "concept assessment");
  overrideScopeIconFunction($scope, 'getStudentOverlay', function(){return IconOverlays.CHECK;});

  var caModel = new spire.ReadingModel();
  $scope.caModel = caModel;
  $scope.words = [];
  $scope.sentences = [];

  var level = 1;
  var lesson = 1;
  var sublesson = 'a';
  var studentScores = {};
  var highScores = {};

  function resetElement()
  {
    spire.resetElement($scope);
    caModel.reset();
    $scope.words = [];
    $scope.sentences = [];
  }

  resetElement();

  function parseElement()
  {
    var element = $scope.options.element;

    element.config.attributes.forEach(function(attribute)
      {
        var name = attribute.name;
        var value = attribute.value;
        switch (name)
        {
          case "words" :
            $scope.words = spire.parseWords(value);
            break;
          case "sentences" :
            $scope.sentences = parseSentences(value);
            break;
          case "level" :
            level = value;
            break;
          case "lesson" :
            lesson = value;
            break;
          case "sublesson" :
            sublesson = value;
            break;
        }
      });

    var phrases = [];
    forAllPhrases(function(phrase){
      if (phrase.isKeyword)
        phrases.push(phrase);
    });

    caModel.setPhrases(phrases);
    getClassConceptAssessmentScores($scope.options.context.clazz.classId, level, lesson).then(updateStudentScores);
  }

  function parseSentences(value)
  {
    var retval = [];
    if (value && value.map)
    {
      retval = value.map(parseSentence);
    }
    return retval;
  }

  function parseSentence(valueObj, index, array)
  {
    var marker = "___";
    var retval = [];

    if (!valueObj.value)
      return retval;

    var value = valueObj.value;
    var inKeyword = false;
    var start = 0;
    var end = value.indexOf(marker);

    if (start == end)
    {
      start = marker.length;
      inKeyword = true;
    }

    while ((end = value.indexOf(marker, start)) != -1)
    {
      retval.push(new spire.Phrase(value.substr(start, end - start), inKeyword));
      start = end + marker.length;
      inKeyword = !inKeyword;
    }

    retval.push(new spire.Phrase(value.substr(start), inKeyword));

    return retval;
  }

  function forAllPhrases(func)
  {
    $scope.words.forEach(func);
    $scope.sentences.forEach(function(sentence){
      sentence.forEach(func);
    });
  }

  function clearUserResponses()
  {
    caModel.setResponse(null);
    $scope.comment.text = '';
  }

  function displayUserResponses(user)
  {
    clearUserResponses();
    var response = null;

    if (!angular.isUndefined(user) && user !== null)
    {
      response = $scope.studentResponses[user];
      if (angular.isUndefined(response) || response === null)
      {
        removeStudentScore($scope.options.context.experienceId, $scope.options.element.template_id, user);
        setEditing(!$scope.options.context.getViewingInactiveExperience());
      }
      else
      {
        setEditing(false);
        $scope.comment.text = response.comment;
      }
    }
    else
    {
      setEditing(false);
    }

    caModel.setResponse(response);
  }

  function setEditing(value)
  {
    caModel.setEditing(value);
  }

  function isEditing()
  {
    return caModel.isEditing();
  }

  $scope.isEditing = isEditing;

  $scope.isPastExperience = function() {
    return $scope.options.context.getViewingInactiveExperience();
  };

  $scope.canSubmit = function()
  {
    return isEditing();
  };

  $scope.getEditMenuItems = function()
  {
    var menuOptions =
    [
      {
        text: '<div class="xp-element-menu-edit">Edit</div>',
        click: 'requestEdit()'
      },
      {
        divider: true
      },
      {
        text: '<div class="xp-element-menu-delete">Delete</div>',
        click: 'requestDelete()'
      }
    ];

    if (isEditing())
    {
      menuOptions =
      [
        {
          text: '<div class="xp-element-menu-edit">Cancel Edit</div>',
          click: 'cancelEdit()'
        }
      ];
    }

    return menuOptions;
  };

  $scope.requestEdit = function()
  {
    setEditing(true);
  };

  $scope.cancelEdit = function()
  {
    if (isResponseModified())
    {
      $scope.displayChangeWarning($scope.selectedStudent);
    }
    else
    {
      setEditing(false);
    }
  };

  $scope.didSubmit = function()
  {
    if ($scope.selectedStudent === null || !$scope.isTeacher || !$scope.isEditing())
      return;

    var response = caModel.getResponse();
    response.comment = $scope.comment.text;

    // Save the user state
    ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.selectedStudent, -1, JSON.stringify(response),
      function() {
        caModel.setResponse(response);
        setEditing(false);
        updateStudentScores([{experience_id: $scope.options.context.experienceId, student_id: $scope.selectedStudent, element_id:$scope.options.element.template_id, sublesson: sublesson, score: 100 * (response.correct / response.possible)}]);
      },
      function(error) {
        ElementsErrorService.error(error);
      });
  };

  function isResponseModified()
  {
    return caModel.isModified() || isCommentModified();
  }

  function isCommentModified()
  {
    var selectedStudent = $scope.selectedStudent;
    if (selectedStudent !== null)
    {
      var response = $scope.studentResponses[selectedStudent];
      if (response)
      {
        return $scope.comment.text != response.comment;
      }
    }

    return $scope.comment.text.length > 0;
  }

  function getClassConceptAssessmentScores(classId, level, lessonId)
  {
    return ClassConceptAssessmentScoresService.get({classId: classId, level: level, lessonId: lessonId}).$promise;
  }

  function updateStudentScores(scores)
  {
    if (scores instanceof Array)
    {
      scores.forEach(function(record){
        var scoreKey = getScoreKey(record.experience_id, record.element_id, record.student_id);
        studentScores[scoreKey] = record;
      });

      rebuildHighScores();
    }
  }

  function rebuildHighScores()
  {
    highScores = {};
    Object.keys(studentScores).forEach(function(key){
      var record = studentScores[key];
      var studentId = record.student_id;
      if (highScores[studentId])
      {
        if(highScores[studentId].score < record.score)
        {
          highScores[studentId] = record;
        }
      } else
      {
        highScores[studentId] = record;
      }
    });
  }

  function getScoreKey(experienceId, elementId, studentId)
  {
    return '' + experienceId + '+' + elementId + '+' + studentId;
  }

  function removeStudentScore(experienceId, elementId, studentId)
  {
    var scoreKey = getScoreKey(experienceId, elementId, studentId);
    delete studentScores[scoreKey];
    rebuildHighScores();
  }

  function shouldDisplayPassedNotification(studentId)
  {
    return highScores[studentId] && Math.round(highScores[studentId].score) >= 80;
  }

  function overrideScopeIconFunction(scope, functionName, override)
  {
    var baseFunction = scope[functionName];

    scope[functionName] = function(studentId) {
      if (shouldDisplayPassedNotification(studentId))
      {
        return override(studentId);
      }

      return baseFunction(studentId);
    };
  }
}]);
})();
