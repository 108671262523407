'use strict';

(function () {

  /**
   * @return {string}
   */
  function toUSDateFormat(d) {
    var date = ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" + ("0" + d.getDate()).slice(-2) +
      "/" + d.getFullYear();
    return date;
  }

  function abbreviateNumber(num) {
    if (!num) {
      return num;
    }
    if (num < 1000) {
      return '' + num;
    }
    var power = ((num).toPrecision(2).split("e")[1] || [0, 0]).slice(1);
    var triplets = Math.floor(power / 3);
    var numericResult = (num / Math.pow(10, triplets * 3)).toFixed(1);
    if (numericResult.length > 4) {
      numericResult = (num / Math.pow(10, triplets * 3)).toFixed();
    }
    return numericResult + ['', 'K', 'M', 'B', 'T'][triplets];
  }

  function formatExperienceSummaryDataRow(row) {
    var date = new Date(row.ends_at);
    var quizSort = row.avg_quiz_score_of_ended;
    if (quizSort === null){
      quizSort = -1;
    }
    var quiz = row.avg_quiz_score_of_ended;
    if (quiz !== null) {
      quiz = Math.round(quiz);
    }
    var avgResp = row.avg_responses_of_started || 0;
    if (avgResp % 1 !== 0) {
      // we need to keep type Number for sorting later, hence the hack instead of toFixed
      avgResp = Math.round(avgResp * 1e1) / 1e1;
    }
    var percCompleted = Math.round(row.avg_progress || 0);
    var timeOnTask = Math.round((row.time_on_task || 0) / 60);
    return {
      archivedInfo: row.archived_info || false,
      prettyName: row.first_name + ' ' + row.last_name,
      teacherEmail: row.email,
      prettyDate: toUSDateFormat(date),
      filterDate: date,
      schoolName: row.school_name,
      className: row.class_name,
      experienceName: row.experience_name,
      experienceId: row.experience_id,
      numberOfStudents: row.students_started,
      percentComplete: percCompleted,
      averageResponsesTotal: avgResp,
      timeOnTask: abbreviateNumber(timeOnTask),
      timeOnTaskSort: timeOnTask || 0,
      quizScore: quiz,
      quizSort: quizSort
    }
  }

  var REPORT_SCOPES = {
    SCHOOL: 'school',
    DISTRICT: 'district',
    CURRICULUM: 'curriculum',
    CLASS: 'class'
  };

  function getReportScope(reportKind) {
    if (reportKind === 'district-experience-summary') {
      return REPORT_SCOPES.DISTRICT;
    }
    if (reportKind === 'curriculum-experience-summary') {
      return REPORT_SCOPES.CURRICULUM;
    }
    if (reportKind === 'school-experience-summary') {
      return REPORT_SCOPES.SCHOOL;
    }
  }

  function isSPIREReport(reportKey) {
    return [
      'subscription-school-spire-hybrid-experience-summary',
      'subscription-district-spire-hybrid-experience-summary',
      'subscription-district-spire-experience-summary',
      'subscription-school-spire-experience-summary'
    ].indexOf(reportKey) > -1;
  }

  const WILDCARDCOLLECTION = "616C6C";

  function isSpireOrWW(collectionIds) {
    return collectionIds.length && collectionIds[0] != WILDCARDCOLLECTION;
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function mapData(data, column) {
    return (data || []).map(function (r) {
      return r[column];
    });
  }

  function countUniqueColumnFn(column) {
    return function (data) {
      return mapData(data, column).filter(onlyUnique).length;
    };
  }

  var summaryTilesGetters = {
    'Teachers': countUniqueColumnFn('teacher_user_id'),
    'Classes': countUniqueColumnFn('class_id'),
    'Schools': countUniqueColumnFn('school_id'),
    'Experiences': countUniqueColumnFn('experience_id'),
    'Responses': function (data) {
      return mapData(data, 'sum_response_count').reduce(function sumAllResponses(sum, value) {
        return sum + value;
      }, 0);
    },
    'Students': function (data) {
      return mapData(data, 'unique_students')
        .reduce(function (a, b) {
          return a.concat(b);
        }, [])
        .filter(onlyUnique).length;
    }
  };

  function generateSummaryTiles(requestedTiles, data) {
    return requestedTiles.map(function (tile) {
      return {
        name: tile.name,
        value: abbreviateNumber(summaryTilesGetters[tile.name](data)),
        guide: tile.guide
      }
    });
  }


  var module = angular.module('client.components');

  controller.$inject = ['$location', '$scope', '$log', 'reportToPDF', 'reportToCSV',
    'ActiveMode', 'ExperienceNavigator', 'NgTableParams', 'SavedFilterState', 'ngTableEventsChannel', 'ReportingSchoolYearsService'];

  function controller($location, $scope, $log, reportToPDF, reportToCSV,
                      ActiveMode, ExperienceNavigator, NgTableParams, SavedFilterState, ngTableEventsChannel, ReportingSchoolYearsService) {

    var ctrl = this;
    ctrl.lastUpdated = window.moment().subtract(1, 'day').format('MM/DD/YYYY');
    ctrl.summaryTiles = [];
    ctrl.showSchools = false;
    ctrl.showTeacher = true;
    ctrl.isSPIRE = false;
    ctrl.inited = false;
    ctrl.tableParams = new NgTableParams(
      {
        // items per page
        count: 12,
        sorting: {filterDate: "desc"}
      },
      {
        dataset: [],
        // options for page size
        counts: [],

      }
    );
    ctrl.reportKind = false;
    ctrl.filterYears = ReportingSchoolYearsService.get();
    ctrl.year = ctrl.filterYears[0].value;
    ctrl.filterYear = ctrl.filterYears[0];
    ctrl.includeArchived = false;

    // ng-table event with all data on all pages filtered
    // to get only displayed data user ngTableParams.data or onAfterReloadData event
    ngTableEventsChannel.onAfterDataSorted(function (ngTableDefaults, ngTableParams, updatedData) {
      if (!updatedData || !updatedData.length) {
        return;
      }
      var experienceIds = updatedData.map(function (row) {
        return row.experienceId;
      });
      var filteredData = ctrl.reportData.filter(function (rec) {
        return experienceIds.indexOf(rec.experience_id) > -1;
      });
      ctrl.summaryTiles = getSummaryTiles(ctrl.reportKind, filteredData);
    }, $scope);


    function getSummaryTiles(reportKind, data) {
      var tiles = [];

      if (reportKind === 'curriculum-experience-summary') {
        tiles = [
          {
            name: 'Students',
            guide: 'in'
          },
          {
            name: 'Classes',
            guide: 'learned'
          },
          {
            name: 'Experiences',
            guide: 'generating'
          },
          {
            name: 'Responses',
            guide: false
          }
        ];
      } else if (getReportScope(reportKind) === REPORT_SCOPES.DISTRICT) {
        tiles = [
          {
            name: 'Schools',
            guide: 'with'
          },
          {
            name: 'Teachers',
            guide: 'taught'
          },
          {
            name: 'Students',
            guide: 'a total of'
          },
          {
            name: 'Experiences',
            guide: 'generating'
          },
          {
            name: 'Responses',
            guide: false
          }
        ]
      } else {
        tiles = [
          {
            name: 'Teachers',
            guide: 'with'
          },
          {
            name: 'Classes',
            guide: 'taught'
          },
          {
            name: 'Students',
            guide: 'a total of'
          },
          {
            name: 'Experiences',
            guide: 'generating'
          },
          {
            name: 'Responses',
            guide: false
          }
        ]
      }

      return generateSummaryTiles(
        tiles,
        data
      );
    }

    // Reload any filtering that was previously set for this report
    var savedFilterState = SavedFilterState.getFilter();
    if (savedFilterState) {
      var newFilter = {};
      if (savedFilterState.schoolName) {
        newFilter.schoolName = savedFilterState.schoolName;
      }
      if (savedFilterState.className) {
        newFilter.className = savedFilterState.className;
      }
      if (savedFilterState.experienceName) {
        newFilter.experienceName = savedFilterState.experienceName;
      }
      if (savedFilterState.prettyDate) {
        newFilter.prettyDate = savedFilterState.prettyDate;
      }
      if (savedFilterState.prettyName) {
        newFilter.prettyName = savedFilterState.prettyName;
      }
      if (savedFilterState.includeArchived) {
        ctrl.includeArchived = savedFilterState.includeArchived;
      }
      if (savedFilterState.filterYear) {
        ctrl.filterYear = savedFilterState.filterYear;
      }
      if (savedFilterState.year) {
        ctrl.year = savedFilterState.year;
      }
      angular.extend(ctrl.tableParams.filter(), newFilter);
    }

    // Save the filter state when leaving this view.
    // Not ideal since it introduces scope but this will just save the filters once when the user leaves this view
    $scope.$on("$destroy", function () {
      var filters = ctrl.tableParams.filter() || {};
      filters.includeArchived = ctrl.includeArchived;
      filters.filterYear = ctrl.filterYear;
      filters.year = ctrl.year;
      SavedFilterState.setFilter(filters);
    });

    ctrl.goToPastExperience = function (expId) {
      var expUrl = '/experience/' + expId + '/dashboard/responses';
      ExperienceNavigator.navigateToExperience(expUrl, "", $location.path());
    };

    ctrl.showDownloadPDF = true;
    ctrl.downloadPDF = function () {
      reportToPDF.ngReport(ctrl, 'showDownloadPDF')('printable', (ctrl.parent.title || 'Experience Summary Report') + '.pdf');
    };
    ctrl.downloadCSV = reportToCSV(
      function () {
        var csvRows = [
          {
            name: 'Date',
            field: 'prettyDate',
            wrap: true
          }
        ];
        if (ctrl.showSchools){
          csvRows.push({
              name: 'School',
              field: 'schoolName',
              wrap: true
            });
        }
        if (ctrl.showTeacher){
          csvRows.push({
            name: 'Teacher',
            field: 'prettyName',
            wrap: true
          });
          csvRows.push({
            name: 'Email',
            field: 'teacherEmail',
            wrap: true
          });
        }

        return csvRows.concat([
          {
            name: 'Class',
            field: 'className',
            wrap: true
          },
          {
            name: 'Experience',
            field: 'experienceName',
            wrap: true
          },
          {
            name: 'Number of participating students',
            field: 'numberOfStudents',
            wrap: false
          },
          {
            name: '% complete',
            field: 'percentComplete',
            wrap: true
          },
          {
            name: 'Average responses per student',
            field: 'averageResponsesTotal',
            wrap: false
          },
          {
            name: 'Average quiz score',
            field: 'quizScore',
            wrap: true
          },
          {
            name: 'Time on task (min.)',
            field: 'timeOnTask',
            wrap: false
          }
        ]);
      },
      function () {
        return ctrl.tableParams.settings().dataset;
      }, function () {
        return (ctrl.parent.title || 'Experience Summary') + '.csv';
      },
      ','
    );

    ctrl.onRefresh = function(downloadPDF, downloadCSV, year, course) {
      if (year) {
        ctrl.toggleYear(year);
      } else if (downloadPDF) {
        ctrl.downloadPDF();
      } else if (downloadCSV) {
        ctrl.downloadCSV();
      }
      else if (course) {
        ctrl.toggleCourse(course);
      }
    };

    function getUniqueCourseList(data) {
      var idToName = {};
      data.forEach(function (rec) {
        if (rec.collection_uuid) {
          idToName[rec.collection_uuid] = rec.collection;
        }
      });
      var uniqueCourses = Object.keys(idToName).map(function(id) {
        var courseRec = data.find(function(rec) {
          return rec.collection_uuid === id;
        });
        return {
          id: courseRec.collection_uuid,
          name: courseRec.collection,
          set_order: courseRec.set_order
        };
      })
      .sort(function(a, b){
        return a.set_order - b.set_order;
      });
      return uniqueCourses;
    }

    function updateReport() {
      ctrl.inited = false;
      return ActiveMode.getReportData({
        include_archived: ctrl.includeArchived,
        year: ctrl.year,
        course: ctrl.course
      }).then(function (report) {
        var kind = report.report_kind;
        ctrl.reportKind = kind;
        if (getReportScope(kind) === REPORT_SCOPES.CURRICULUM) {
          ctrl.showTeacher = false;
        } else {
          ctrl.showTeacher = true;
        }
        if (getReportScope(kind) === REPORT_SCOPES.DISTRICT) {
          ctrl.showSchools = true;
        } else {
          ctrl.showSchools = false;
        }

        ctrl.isSPIRE = isSPIREReport(report.report_key);

        ctrl.tableParams.settings({
          dataset: report.data.map(formatExperienceSummaryDataRow),
        });

        if (!isSpireOrWW(report.collection_uuids) && (!ctrl.courses || ctrl.courses.length === 0)) {
          ctrl.courses = getUniqueCourseList(report.data);
        }

        ctrl.reportData = report.data;

        ctrl.inited = true;

        return report.data;
      })
        .catch(function (error) {
          $log.error("error in getting report data:", error);
          ctrl.inited = true;
        });
    }

    updateReport();

    ctrl.toggleYear = function toggleYear(selection) {
      ctrl.year = selection.value;
      ctrl.filterYear = selection;
      ctrl.includeArchived = selection.archived;
      ctrl.courses = [];
      updateReport();
    };

    ctrl.toggleCourse = function(course) {
      if (course.id !== "All") {
        ctrl.course = course.id;
      } else {
        ctrl.course = undefined;
      }
      ctrl.filterCourse = course;
      updateReport();
    };

    ctrl.goToPastExperience = function (expId) {
      var expUrl = '/experience/' + expId + '/dashboard/responses';
      ExperienceNavigator.navigateToExperience(expUrl, "", $location.path());
    };

  }

  module.component('experienceSummary', {
    require: {parent: '^^xpReport'},
    template: require('./experienceSummaryReport.jade'),
    controller: controller,
  });

})();
