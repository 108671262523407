const WS = require('reconnecting-websocket');
const Helper = require('./helpers/realtime_helper');
const realtimeURL = require('../../config').realtime.url;

let webSocketClient;
let realtimeHub;

function createRealtimeHub () {
  let eventTarget = new Helper.EventTarget();
  eventTarget.removeListener = eventTarget.removeEventListener;
  eventTarget.on = eventTarget.addEventListener;
  eventTarget.off = eventTarget.removeEventListener;
  eventTarget.send = function sendRealtimeMessageFromClient (data) {
    if (webSocketClient) {
      let message = {
        client_datetime: new Date(),
        payload: data
      };
      webSocketClient.send(JSON.stringify(message));
    }
  };
  return eventTarget;
}

const CLOSE_REASONS = {
  NEW_CLIENT: 'new-client',
  LOGOUT: 'logout',
  LOGIN: 'login',
  TIMEOUT: 'timeout'
};

const MAX_RETRIES = 2;

exports.CLOSE_REASONS = CLOSE_REASONS;

exports.initRealtime = function initRealtime (token) {
  if (webSocketClient) {
    webSocketClient.close();
  }
  let errorCount = 0;
  webSocketClient = new WS.default(`${realtimeURL}/?token=${token}`, null, {
    connectionTimeout: 5000,
    minReconnectionDelay: 3000,
    maxRetries: MAX_RETRIES
  });
  realtimeHub = createRealtimeHub();

  // webSocketClient.addEventListener('close', function (event) {
  //   let payload;
  //   try {
  //     payload = JSON.parse(event.data);
  //   } catch (e) {
  //     console.error('failed to parse incoming realtime data: ', e);
  //     return false;
  //   }
  //
  //   if (payload.event && realtimeHub) {
  //     const newEvent = new Helper.CustomEvent(payload.event, {detail: payload.message});
  //     realtimeHub.dispatchEvent(newEvent);
  //     if (payload.event === 'message' && payload.message && payload.message.subject){
  //       const newSubEvent = new Helper.CustomEvent(payload.message.subject, {detail: {record: payload.message.body}});
  //       realtimeHub.dispatchEvent(newSubEvent);
  //     }
  //   }
  // });

  webSocketClient.addEventListener('error', function (error) {
    errorCount = errorCount + 1;
    if (errorCount <= MAX_RETRIES) {
      return;
    }
    const newEvent = new Helper.CustomEvent('error', { detail: { error } });
    realtimeHub.dispatchEvent(newEvent);
  });

  webSocketClient.addEventListener('open', function () {
    errorCount = 0;
  });

  webSocketClient.addEventListener('message', function (event) {
    let payload;
    try {
      payload = JSON.parse(event.data);
    } catch (e) {
      console.error('failed to parse incoming realtime data: ', e);
      return false;
    }

    if (!(payload.event && realtimeHub)) {
      return false;
    }

    try {
      const newEvent = new Helper.CustomEvent(payload.event, { detail: payload.message });
      realtimeHub.dispatchEvent(newEvent);
      if (payload.event === 'message' && payload.message && payload.message.subject) {
        const newSubEvent = new Helper.CustomEvent(payload.message.subject, {
          detail: {
            record: payload.message.body,
            from: payload.message.from,
            to: payload.message.to
          }
        });
        realtimeHub.dispatchEvent(newSubEvent);
      }
      if (payload.event === 'logout') {
        const newSubEvent = new Helper.CustomEvent('logout', { detail: { reason: payload.message.reason } });
        realtimeHub.dispatchEvent(newSubEvent);
      }
    } catch (e) {
      console.error('failed to dispatch an event:', e);
    }
  });

  return realtimeHub;
};

exports.turnOffRealtime = function turnOffRealtime (reason) {
  if (webSocketClient) {
    webSocketClient.close(1000, reason);
    realtimeHub = function noEventTargetDefined () {};
  }
};

exports.getClient = function getClient () {
  return realtimeHub;
};

exports.sendMessage = function sendMessage (payload) {
  if (realtimeHub) {
    realtimeHub.send(payload);
  }
  return realtimeHub;
};
