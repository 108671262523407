'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$resource', '$log', 'SavedFilterState', 'ReportingSchoolYearsService', 'DistrictAdminMode'];

function controller ($resource, $log, SavedFilterState, ReportingSchoolYearsService, DistrictAdminMode) {
  var ctrl = this;

  var subscriptionSchoolResource =
    $resource('/main/v1' + '/subscriptions/admin/subscriptions/:subscriptionId/experiencefilters', {},
        {get: {method: 'GET', params: {subscriptionId: '@subscriptionId'}}});

  ctrl.selectedSchools = [];
  ctrl.availableSchools = [];
  ctrl.savedFilteredSchools = [];

  ctrl.selectedTeachers = [];
  ctrl.availableTeachers = [];
  ctrl.savedFilteredTeachers = [];

  ctrl.selectedCourses = [];
  ctrl.availableCourses = [];
  ctrl.savedFilteredCourses = [];

  // Create a default all schools selection
  var allSchools = {id: "All", name: "All Campuses", ticked: true};

  // Create a default all teachers selection
  var allTeachers = {teacher_user_id: "All", first_name: "All", last_name: "Teachers", ticked: true};

  // Create a default all courses selection
  var allCourses = {id: "All", name: "All Courses", ticked: true};

  ctrl.$onInit = function () {
    // Get the schools, teachers and courses for the subscription
    if (ctrl.subscriptionId) {
      subscriptionSchoolResource.get({subscriptionId: ctrl.subscriptionId}).$promise
      .then(function(response) {
        if (DistrictAdminMode.isActiveMode()) {
          ctrl.availableSchools = response.schools.map(function(school){
            return  {
                    id: school.school_id,
                    name: school.school_name,
                    ticked: false,
                    count: school.experience_count
                    };
          });
          ctrl.availableSchools.unshift(allSchools);
          ctrl.setCurrentSchools();
        }

        ctrl.availableTeachers = response.teachers.map(function(teacher){
          return  {
                  teacher_user_id: teacher.teacher_user_id,
                  first_name: teacher.teacher_first_name,
                  last_name: teacher.teacher_last_name,
                  count: teacher.experience_count
                  };
        });
        ctrl.availableTeachers.unshift(allTeachers);
        ctrl.setCurrentTeachers();

        ctrl.availableCourses = response.collections.map(function(collection){
          return  {
                  id: collection.top_collection_uuid,
                  name: collection.name,
                  count: collection.experience_count
                  };
        });
        ctrl.availableCourses.unshift(allCourses);
        ctrl.setCurrentCourses();
      })
      .catch(function(error){
        $log.error("error in getting schools: ", error);
      });
    }

    ctrl.InitializeFilters();
  };

  ctrl.InitializeFilters = function() {
    // Initialize the filter based on session storage values
    var savedFilterState = SavedFilterState.getState();
    if (savedFilterState) {
      ctrl.savedFilteredSchools = savedFilterState.schools;
      ctrl.savedFilteredTeachers = savedFilterState.teachers;
      ctrl.savedFilteredCourses = savedFilterState.courses;

      // Call the experience list to update itself with the saved filters
      if (ctrl.refresh) {
        var experienceFilter = {
              schools: ctrl.savedFilteredSchools,
              teachers: ctrl.savedFilteredTeachers,
              courses: ctrl.savedFilteredCourses,
            };
        ctrl.refresh(experienceFilter);
      }
    }
  };

  ctrl.$onChanges = function(changes) {
    // Prepend the all teacher onto the teachers list
    if (changes.teachers && changes.teachers.currentValue) {
      var newTeacherArray = changes.teachers.currentValue.slice(0);
      newTeacherArray.unshift(allTeachers);
      ctrl.availableTeachers = newTeacherArray;
      ctrl.setCurrentTeachers();
    }

    // Prepend the All courses to the courses list
    if (changes.courses && changes.courses.currentValue) {
      var newCourseArray = changes.courses.currentValue.slice(0);
      newCourseArray.unshift(allCourses);
      ctrl.availableCourses = newCourseArray;
      ctrl.setCurrentCourses();
    }

    // Any changes requires setting filters
    // ctrl.InitializeFilters();
  };

  ctrl.setCurrentSchools = function() {
    if (ctrl.availableSchools.length > 0 && ctrl.savedFilteredSchools && ctrl.savedFilteredSchools.length > 0) {
      // Need to uncheck All schools since it is on by default
      ctrl.availableSchools[0].ticked = false;

      // Loop through and tick all available schools that were saved as part of the filter
      ctrl.availableSchools.forEach(function(availableSchool) {
        // See if this school exists in the saved filter list
        ctrl.savedFilteredSchools.forEach(function(filteredSchool) {
          if (availableSchool.id === filteredSchool.id) {
            availableSchool.ticked = true;
          }
        });
      });
    }
  };

  ctrl.setCurrentTeachers = function() {
    if (ctrl.availableTeachers.length > 0 && ctrl.savedFilteredTeachers && ctrl.savedFilteredTeachers.length > 0) {
      // Need to uncheck All Teachers since it is on by default
      ctrl.availableTeachers[0].ticked = false;

      // Loop through and tick all available teachers that were saved as part of the filter
      ctrl.availableTeachers.forEach(function(availableTeacher) {
        // See if this teacher exists in the saved filter list
        ctrl.savedFilteredTeachers.forEach(function(filteredTeacher) {
          if (availableTeacher.teacher_user_id === filteredTeacher.teacher_user_id) {
            availableTeacher.ticked = true;
          }
        });
      });
    }
  };

  ctrl.setCurrentCourses = function() {
    if (ctrl.availableCourses.length > 0 && ctrl.savedFilteredCourses && ctrl.savedFilteredCourses.length) {
      // Need to uncheck All courses since it is on by default
      ctrl.availableCourses[0].ticked = false;

      // Loop through and tick all available courses that were saved as part of the filter
      ctrl.availableCourses.forEach(function(availableCourse) {
        // See if this course exists in the saved filter list
        ctrl.savedFilteredCourses.forEach(function(filteredCourse) {
          if (availableCourse.id === filteredCourse.id) {
            availableCourse.ticked = true;
          }
        });
      });
    }
  };

  // This code makes sure that the "All Schools" selection and any school selections are kept exclusive.
  // Basically you cannot select All Schools and individual schools at the same time.
  ctrl.onSchoolClicked = function(item) {
    // if this is NOT the all schools then make sure the ALL Schools is NOT checked
    if (item.id !== "All") {
      ctrl.availableSchools[0].ticked = false;
    }
    else {
      // Un-check all other schools since all schools is currently selected
      ctrl.availableSchools.forEach(function(school, index) {
        if (index !== 0 && school.ticked) {
          school.ticked = false;
        }
      });
    }
  };

  // This code makes sure that the "All Teachers" selection and any teacher selections are kept exclusive.
  // Basically you cannot select All Teachers and individual teachers at the same time.
  ctrl.onTeacherClicked = function(item) {
    // if this is NOT the all teachers then make sure the ALL Teachers is NOT checked
    if (item.teacher_user_id !== "All") {
      ctrl.availableTeachers[0].ticked = false;
    }
    else {
      // Uncheck all other teachers since all teachers is currently selected
      ctrl.availableTeachers.forEach(function(teacher, index) {
        if (index !== 0 && teacher.ticked)
          teacher.ticked = false;
      });
    }
  };

  // This code makes sure that the "All Courses" selection and any course selections are kept exclusive.
  // Basically you cannot select All Courses and individual courses at the same time.
  ctrl.onCourseClicked = function(item) {
    // if this is NOT the all classes then make sure the ALL Courses is NOT checked
    if (item.id !== "All") {
      ctrl.availableCourses[0].ticked = false;
    }
    else {
      // Uncheck all other courses since all courses is currently selected
      ctrl.availableCourses.forEach(function(course, index) {
        if (index !== 0 && course.ticked)
          course.ticked = false;
      });
    }
  };

  ctrl.onUpdateExperienceList = function() {
    if (ctrl.refresh) {
      var experienceFilter = {
          schools: ctrl.selectedSchools,
          teachers: ctrl.selectedTeachers,
          courses: ctrl.selectedCourses};
      SavedFilterState.setState(experienceFilter);
      ctrl.refresh(experienceFilter);
    }
  };
}

module.component('experienceListFilters', {
  template: require('./experienceListFilters.jade'),
  controller: controller,
  bindings: {
    style: '@',
    subscriptionId: '<',
    refresh: '&'
  }
});

})();
