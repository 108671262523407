'use strict';

(function() {
  var module = angular.module('client.components');

  controller.$inject = ['$log', 'ActiveMode', 'userPermissions', 'PermissionConsts', 'ModalService'];

function controller ($log, ActiveMode, userPermissions, PermissionConsts, ModalService) {
  var ctrl = this;

  ctrl.navigateToReport = function(reportId) {
    ActiveMode.showReport(reportId);
  };

  if(ActiveMode.isCurriculumMode() && !userPermissions.hasPermission(PermissionConsts.ui_curriculum_hide_reports_placeholder)){
    ModalService.show({
      feature: 'curriculum_reports_placeholder',
      template: require('../../views/partials/modals/subscriptionFeatureModal.jade'),
      backdrop: 'static',
    });
    
    ActiveMode.navigateToExperiences();
  }

  // Get the correct set of reports based on the current mode
  ActiveMode.getReports().then(function(reports) {
    ctrl.reports = reports;
  })
  .catch(function(error) {
    $log.error("error in getting reports:",error);
    ctrl.reports = [];
  });

}

module.component('xpReportPicker', {
  template: require('./reportPicker.jade'),
  controller: controller,
});

})();
