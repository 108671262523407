'use strict';

angular.module('client.services').factory('LicenseValidation', function ($resource) {
  return $resource('/main/v1' + '/subscriptions/validate/:subid', {subid: '@subid'}, {
    post: {
      method: 'POST',
      isArray: true,
      cache: true,
      params: {subid: '@subid'}
    }
  });
});
