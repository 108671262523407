'use strict';

angular.module('client.services').factory('importTeachersClassesStudents', function ($resource) {
  var url = "/main/v1" + '/classes/import';
  return $resource(url, {subid: '@subid', userId: '@userId', districtId: '@districtId'}, {
    import: {url: url, method: 'POST', params: {subid: '@subid'}},
    sendEmail: {url: url + '/email', method: 'PUT', params: {subid: '@subid', userId: '@userId'}},
    sendInstructions: {url: url + '/instructions_email', method: 'PUT', params: {subid: '@subid'}},
    importTeachers: {url: url + '/teachers', method: 'POST', params: {subid: '@subid'}},
    importDistrictTeachers: {url: url + '/districts/:subscriptionId/teachers', method: 'POST', params: {subscriptionId: '@subscriptionId'}}
  });
});
