'use strict';

angular.module('client.services').factory('VideoStreamingService', ['$resource', '$log', 'ActiveExperience',
                                                                    'User',
  function ($resource, $log, ActiveExperience,
            User) {

    var teacherAllowedStudentsToRaiseHands = false;
    var currentSceneId = 1;
    var AUDIOSTATE = Object.freeze({OFFLINE: 'OFFLINE', PENDING: 'PENDING', ACCEPTED: 'ACCEPTED', DECLINED: 'DECLINED', SPEAKING: 'SPEAKING', MUTED: 'MUTED'});

    var mediaTokenResource = $resource('/main/v1' + '/experiences/:experience_id/media/gettoken', null,
      {
        'get': {method: 'GET'}
      });

    var teacherStreamResource = $resource('/main/v1' + '/experiences/:experience_id/media/teacher_streaming',
      {experience_id: '@experience_id', status: '@status', scene_ind: '@scene_ind'},
      {
        'post': {method: 'POST', params: {status: '@status', scene_ind: '@scene_ind'}}
      });

    var raiseHandResource = $resource('/main/v1' + '/experiences/:experience_id/media/:action',
      {
        experience_id: '@experience_id', action: '@action', scene_ind: '@scene_ind',
        allow_raise_hand: '@allow_raise_hand', hand_up: '@hand_up'
      },
      {
        'allowRaiseHand': {method: 'POST', params: {allow_raise_hand: '@allow_raise_hand'}},
        'raiseHand': {method: 'POST', params: {scene_ind: '@scene_ind', hand_up: '@hand_up'}},
    });

    var studentAudioResource = $resource('/main/v1' + '/experiences/:experience_id/media/:action',
      {
        experience_id: '@experience_id', action: '@action', scene_ind: '@scene_ind',
        student_id: '@student_id', enable_talking: '@enable_talking', status: '@status'
      },
      {
        'callOnStudent': {
          method: 'POST',
          params: {scene_ind: '@scene_ind', student_id: '@student_id', enable_talking: '@enable_talking'}
        },
        'studentResponse': {
          method: 'POST',
          params: {scene_ind: '@scene_ind', status: '@status'}
        },
    });

    function getMediaToken(experienceId) {
      return mediaTokenResource.get({experience_id: experienceId}).$promise;
    }

    function setTeacherStreamingStatus(experienceId, status, sceneId) {
      return teacherStreamResource.post({
        experience_id: experienceId,
        status: status,
        scene_ind: sceneId
      }).$promise;
    }

    function allowRaiseHand(enable) {
      teacherAllowedStudentsToRaiseHands = enable;
      return raiseHandResource.allowRaiseHand({
        action: 'raise_hand',
        experience_id: ActiveExperience.currentExperience().id,
        allow_raise_hand: enable ? "yes" : "no"
      }).$promise;
    }

    function toggleStudentHandRaised(handRaised) {
        return raiseHandResource.raiseHand({
          action: 'student_raised_hand',
          experience_id: ActiveExperience.currentExperience().id,
          scene_ind: currentSceneId,
          hand_up: handRaised
        }).$promise;
    }

    function callOnStudent(studentId, enable) {
      return studentAudioResource.callOnStudent({
        action: 'allow_student_audio',
        experience_id: ActiveExperience.currentExperience().id,
        scene_ind: currentSceneId,
        student_id: studentId,
        enable_talking: enable
      }).$promise;
    }

    function setStudentStatus(studentAudioState) {
      return studentAudioResource.studentResponse({
        action: 'student_audio_response',
        experience_id: ActiveExperience.currentExperience().id,
        scene_ind: currentSceneId,
        status: studentAudioState
      }).$promise;
    }

    function setCurrentScene(sceneId) {
      currentSceneId = sceneId;
    }

    function getStudentCanRaiseHand() {
      return teacherAllowedStudentsToRaiseHands;
    }

    return {
      getMediaToken: getMediaToken,
      setTeacherStreamingStatus: setTeacherStreamingStatus,
      toggleStudentHandRaised: toggleStudentHandRaised,
      setCurrentScene: setCurrentScene,
      allowRaiseHand: allowRaiseHand,
      setStudentStatus: setStudentStatus,
      callOnStudent: callOnStudent,
      getStudentCanRaiseHand: getStudentCanRaiseHand,
      AUDIOSTATE: AUDIOSTATE
    };
  }]);

