'use strict';

(function () {
  function renderTotalResponses(val) {
    if(val === '-'||!val){
      return '-'
    }
    return abbreviateNumber(val);
  }

  function abbreviateNumber(num) {
    if(num<1000){
      return ''+num;
    }
    var power = ((num).toPrecision(2).split("e")[1]||[0,0]).slice(1);
    var triplets = Math.floor(power / 3);
    var numericResult = ( num / Math.pow(10, triplets * 3) ).toFixed(1);
    if(numericResult.length > 4){
      numericResult = ( num / Math.pow(10, triplets * 3) ).toFixed();
    }
    return numericResult + ['', 'K', 'M', 'B', 'T'][triplets];
  }

  function renderQuizScore(val) {
    return (!val && val !==0) ? '-' : val + '%';
  }

  function renderExperienceTaught(rec) {
    if(rec.masters_touching_breakout_count){
      return (rec.experiences_taught_count||0) + '/' + rec.masters_touching_breakout_count;
    } else {
      return '';
    }
  }

  function getTimeOnTask(rec) {
    var value = Math.round(rec.time_on_standard || 0);
    return value ? abbreviateNumber(value) : '-';
  }

  function getBreakoutCode(rec) {
    var codes = (rec.breakout_code || '').split('.');
    var code = codes[codes.length - 1];
    return code ? '('+code+') ' : '';
  }

  function formatExperienceSummaryDataRow(rec) {
    return {
      standard: rec.standard,
      sort_order: rec.expectation_code + rec.breakout_order,
      breakout: rec.breakout_label,
      breakout_code: getBreakoutCode(rec),
      taught: renderExperienceTaught(rec),
      experiences: parseExpBlob(rec.masters_list),
      masters: rec.masters_list,
      students: rec.experiences_taught_count ? ((rec.responding_students_count || 0)||'-'):'',
      responses: rec.experiences_taught_count ? renderTotalResponses(rec.responses):'',
      quiz: rec.experiences_taught_count ? renderQuizScore(rec.avg_quiz_item_score):'',
      time: rec.experiences_taught_count ? getTimeOnTask(rec) : ''
    };
  }

  function setColumns(ctrl) {
    ctrl.columns = [
      { field: 'standard',
        headerTitle: ctrl.getLabel('label-details-column-standard', 'Standard'),
        titleName: ctrl.getLabel('label-details-column-standard', 'Standard'),
        class: 'xp-report-cell',
        groupable: 'standard',
        show: false,
      },
      { field: 'breakout',
        title: ctrl.getLabel('label-details-column-standards', 'Standards'),
        titleName: ctrl.getLabel('label-details-column-standards', 'Standards'),
        filter:{ standard: 'text'},
        class: 'xp-report-cell',
        show: true,
        groupable: false,
      },
      { field: 'taught',
        headerTitle:'No. of experiences taught/available',
        titleName:'No. of experiences taught/available',
        class: 'text-center xp-report-tiny-cell',
        show: true,
        groupable: false,
        headerTemplateURL:'xp-report-experience-details-header-taught',
      },
      { field: 'students',
        headerTitle: ctrl.getLabel('label-details-column-students', 'Number of students learning the standard'),
        titleName: ctrl.getLabel('label-details-column-students', 'Number of students learning the standard'),
        class: 'text-center xp-report-tiny-cell',
        show: true,
        groupable: false,
        headerTemplateURL:'xp-report-experience-details-header-students',
      },
      { field: 'responses',
        headerTitle:'Total responses',
        titleName:'Total responses',
        class: 'text-center xp-report-tiny-cell',
        show: true,
        groupable: false,
        headerTemplateURL:'xp-report-experience-details-header-responses',
      },
      { field: 'quiz',
        headerTitle: 'Average quiz score',
        titleName: 'Average quiz score',
        class: 'text-center xp-report-tiny-cell',
        show: true,
        groupable: false,
        headerTemplateURL:'xp-report-experience-details-header-quiz',
      },
      { field: 'time',
        headerTitle: ctrl.getLabel('label-details-column-time', 'Time on breakout (min.)'),
        titleName: ctrl.getLabel('label-details-column-time', 'Time on breakout (min.)'),
        class: 'text-center xp-report-tiny-cell',
        show: true,
        groupable: false,
        headerTemplateURL:'xp-report-experience-details-header-time',
      }
    ];
  }

  function renderReport(ctrl, data) {
    setColumns(ctrl);
    ctrl.tableParams.settings({
      group: 'standard',

      dataset: data
    });
  }

  function generateUniqueClassList(data) {
    var dataWithExperiences = data.filter(function(dwe) {
      return dwe.experiences_taught_count;
    });
    if (!dataWithExperiences || dataWithExperiences.length === 0) {
      return [{id:'none',name:'No Value'}];
    }
    var idToName = {};
    dataWithExperiences.forEach(function (rec) {
      idToName[rec.class_id] = rec.class_name;
    });
    var uniqueClasses = Object.keys(idToName).map(function(id) {
      var classRec = data.find(function(rec) {
        return rec.class_id === parseInt(id, 10);
      });
      return {
        id: classRec.class_id,
        name: classRec.class_name,
        status: classRec.class_status
      };
    })
    .sort(function(a, b){
      return a.name.localeCompare(b.name);
    });
    var active = uniqueClasses.filter(function(cl){
      return cl.status.toUpperCase() === 'ACTIVE';
    });
    var inActive = uniqueClasses.filter(function(cl){
      return cl.status.toUpperCase() !== 'ACTIVE';
    });
    if (inActive.length === 0) {
      return active;
    }
    return active.concat({id: 'none', name: '', status: 'DIVIDER'}).concat(inActive);
  }

  function parseStudentBlob(rec) {
    if(!rec.students){
      return {};
    }
    var jsonBlob = "["+rec.students+"]";
    try{
      var std = JSON.parse(jsonBlob);
      return {
        students: std,
        class_id: rec.class_id
      };
    }catch(e){
      return {};
    }
  }
  function getLastName(name) {
    return (name||'').split(' ')[name.split(' ').length - 1];
  }
  function getListOfStudents(recs, classId){
    var studentsById = {};
    recs.map(parseStudentBlob).forEach(function (records) {
      if(records.class_id !==classId){ return; }
      records.students.forEach(function (student) {
        studentsById[student.user_id] = student;
      });
    });
    var studentsList = [{
      name: 'All Students',
      user_id: 'all',
      class_id: 'all'
    }];
    Object.keys(studentsById).map(function (id) {
      return {
        user_id: parseInt(id,10),
        name: studentsById[id].name
      }
    })
      .sort(function(a, b) {
        return getLastName(a.name).localeCompare(getLastName(b.name),'en',{numeric:true});
      })
      .forEach(function (student) {
        studentsList.push(student);
      });
    return studentsList;
  }

  function getReportFilter(ctrl) {
    return function (rec) {
      var filter = (
        rec.type_id === ctrl.filterType.id   &&
        rec.concept_id === ctrl.filterConcept.id &&
        ((rec.class_id && ctrl.filterClass) ? rec.class_id === ctrl.filterClass.id : true)
      );
      if(!ctrl.showSuggested || (ctrl.year !== ctrl.filterYears[0].value)){
        filter = filter && rec.experiences_taught_count;
      }
      return filter;
    }
  }

  function mergeByBreakoutCode(recs) {
    var byKey = {};
    recs.forEach(function (rec) {
      var key = rec.sort_order + rec.breakout_code;
      if(!byKey[key]){
        byKey[key] = rec;
        return true;
      }
      byKey[key].experiences = (byKey[key].experiences||[]).concat(rec.experiences||[]);
      var taughtExperiences = (byKey[key].experiences||[])
        .filter(function (exp) {
          return exp.was_taught;
        }).sort(compareTaughtExperiences);

      var uniqueNonTaughtNames = [];
      var nonTaughtExperiences = (byKey[key].experiences||[])
        .filter(function (exp) {
          return !exp.was_taught;
        })
        .filter(function useOnlyUniqueNames(exp) {
          if (uniqueNonTaughtNames.indexOf(exp.name)>-1){
            return false;
          } else {
            uniqueNonTaughtNames.push(exp.name);
            return true;
          }
        })
        .sort(function(a, b) {
          return a.name.localeCompare(b.name,'en',{numeric:true});
        });

      byKey[key].experiences = taughtExperiences.concat(nonTaughtExperiences);

      byKey[key].quiz = byKey[key].quiz || rec.quiz;
      byKey[key].responses = renderTotalResponses(byKey[key].responses || rec.responses);
      byKey[key].students = byKey[key].students || rec.students;
      byKey[key].time = byKey[key].time || rec.time;
      if(rec.taught > byKey[key].taught){
        byKey[key].taught = rec.taught;
      }
    });

    return Object.keys(byKey).map(function (key) {
      return byKey[key];
    });
  }

  function updateReport(ctrl) {
    var reportData;
    var summariesData;
    if(ctrl.useReloadedRecords){
      reportData = ctrl.reloadedData;
      summariesData = ctrl.reloadeSummariesdData;
    } else {
      reportData = ctrl.reportData;
      summariesData = ctrl.summariesData;
    }
    ctrl.data = mergeByBreakoutCode((reportData||[]).filter(getReportFilter(ctrl)).map(formatExperienceSummaryDataRow)).sort(function (a,b) {
      return a.sort_order.localeCompare(b.sort_order,'en',{numeric:true});
    });

    ctrl.summaries = getSummaryCells((summariesData||[]).filter(function (r) {
      return (r.class_id === ctrl.filterClass.id||!r.class_id) &&
        r.type_id === ctrl.filterType.id &&
        r.concept_id === ctrl.filterConcept.id;
    }));

    renderReport(ctrl, ctrl.data);
  }

  function filterConcepts(data) {
    var filteredColumn = 'concept';
    var columnId = filteredColumn + '_id';
    var columnName = filteredColumn + '_label';
    var idToName = {};
    Object.keys(data).forEach(function (key) {
      idToName[data[key][columnId]]= data[key][columnName];
    });
    return Object.keys(idToName)
      .sort(function(a, b) {
        return a.localeCompare(b,'en',{numeric:true});
      })
      .map(function (id) {
        return {
          id: parseInt(id, 10),
          name: idToName[id]
        };
      });
  }

  var typesOrder = [
    'Knowledge and Skills - Readiness',
    'Knowledge and Skills - Supporting',
    'Process - Tools to Know',
    'Process - Ways to Show',
    'Not STAAR Assessed'
  ].map(function (name) {
    return name.toLowerCase();
  });

  function filterTypes(allRecs, conceptId) {
    var filteredColumn = 'type';
    var columnId = filteredColumn + '_id';
    var columnName = filteredColumn + '_label';
    var idToName = {};
    var data = allRecs.filter(function (rec) {
      return rec.concept_id === conceptId;
    });
    Object.keys(data)
      .forEach(function (key) {
        idToName[data[key][columnId]]= data[key][columnName];
      });
    var types = Object.keys(idToName)
      .sort(function(a, b) {
        return a.localeCompare(b,'en',{numeric:true});
      })
      .map(function (id) {
        return {
          id: parseInt(id, 10) || 0,
          name: idToName[id]
        };
      });
    var firstByOrder = [];
    typesOrder.forEach(function (typeName) {
      types.forEach(function (tp, index, selfRef) {
        if(tp.name.toLowerCase()===typeName){
          firstByOrder.push(selfRef.splice(index,1)[0]);
        }
      });
    });
    return firstByOrder.concat(types);
  }

  function compareTaughtExperiences(a, b) {
    return a.name.localeCompare(b.name,'en',{numeric:true}) || ((new Date(a.ends_at||0)).getTime() - (new Date(b.ends_at||0)).getTime());
  }

  function parseExpBlob(expRaw){
    var jsonBlob = "["+expRaw+"]";
    try{
      var listedBlankExp = {};
      var experiences = JSON.parse(jsonBlob)
        .filter(function (exp) {
          if(exp.was_taught){ return true;}
          if(listedBlankExp[exp.name]){return false;}
          listedBlankExp[exp.name]=true;
          return true;
        });

      var taughtExperiences = experiences
        .filter(function (exp) {
          return exp.was_taught;
        }).sort(compareTaughtExperiences);

      var nonTaughtExperiences = experiences
        .filter(function (exp) {
          return !exp.was_taught;
        })
        .sort(function(a, b) {
          return a.name.localeCompare(b.name,'en',{numeric:true});
        });


      return taughtExperiences.concat(nonTaughtExperiences);
    }catch(e){
      return [];
    }
  }

  function exportAsCSV(records, columns) {
    var headers = [columns.map(function (column) {
      if(column.field === 'breakout'){
        return 'Breakout';
      }
      return column.titleName;
    })];
    var csv = headers.concat(records
      .map(function (rec) {
        return columns.map(function (column) {
          if(column.field === 'standard' || column.field === 'breakout'){
            return '"'+rec[column.field]+'"';
          }
          return rec[column.field];
        });
      }))
      .map(function (row) {
        return row.join(',');
      })
      .join('\n');

    var filename = 'export.csv';

    csv = 'data:text/csv;charset=utf-8,' + csv;

    var link = document.createElement('a');
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  function getSummaryCells(rawSummaries) {
    var summaries = {};

    rawSummaries.forEach(function (sum) {
      if(!summaries[sum.standard]){
        summaries[sum.standard] = {
          experiences_taught_count: 0,
          masters_touching_standard_count: 0,
          experiences: [],
          studentIds: [],
          taught: '0/0',
          students: 0,
          responses: 0,
          avg_quiz_item_score: 0,
          time_on_standard: 0,
          time: '-',
          quiz: '-'
        };
      }
      summaries[sum.standard].experiences_taught_count += (sum.experiences_taught_count||0);
      summaries[sum.standard].time_on_standard += (sum.time_on_standard||0);
      if(sum.responses){
        summaries[sum.standard].responses = sum.responses;
      }
      summaries[sum.standard].avg_quiz_item_score = sum.avg_quiz_item_score;
      (parseStudentBlob(sum).students || []).forEach(function (rec) {
        summaries[sum.standard].studentIds.push(rec.user_id);
      });
      summaries[sum.standard].experiences = summaries[sum.standard].experiences.concat(parseExpBlob(sum.masters_list));

      summaries[sum.standard].masters_touching_standard_count = (summaries[sum.standard].experiences||[])
        .map(function (xp) {
          return (xp||{}).uuid;
        })
        .filter(function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
          }
        ).length||0;

      var taughtExperiences = summaries[sum.standard].experiences
        .filter(function (exp) {
          return exp.was_taught;
        }).sort(compareTaughtExperiences);

      var nonTaughtExperiences = summaries[sum.standard].experiences
        .filter(function (exp) {
          return !exp.was_taught;
        })
        .sort(function(a, b) {
          return a.name.localeCompare(b.name,'en',{numeric:true});
        });

      summaries[sum.standard].experiences = taughtExperiences.concat(nonTaughtExperiences);

      summaries[sum.standard].studentIds = summaries[sum.standard].studentIds.filter(function (st, index, arr) {
        return arr.indexOf(st)===index;
      });

      summaries[sum.standard].taught = summaries[sum.standard].experiences_taught_count + '/' + summaries[sum.standard].masters_touching_standard_count;
      summaries[sum.standard].students = summaries[sum.standard].studentIds.length || '-';
      if(summaries[sum.standard].time_on_standard){
        summaries[sum.standard].time = abbreviateNumber(summaries[sum.standard].time_on_standard);
      }
      if(summaries[sum.standard].avg_quiz_item_score!==null && summaries[sum.standard].avg_quiz_item_score!=='-'){
        summaries[sum.standard].quiz = summaries[sum.standard].avg_quiz_item_score + '%';
      }
    });
    return summaries;
  }

  var module = angular.module('client.components');

  controller.$inject = ['$location', 'ActiveMode', 'NgTableParams', '$log', 'reportLabels', 'ReportingSchoolYearsService',
                        'noTypeReportsList', 'ExperienceNavigator', '$rootScope'];

  function controller($location, ActiveMode, NgTableParams, $log, reportLabels, ReportingSchoolYearsService,
                      noTypeReportsList, ExperienceNavigator, $rootScope) {

    var ctrl = this;
    ctrl.hasTypes = true;
    var namedColumns = ['standard','breakout'];

    ctrl.getLabel = new reportLabels('labels', ctrl);

    ctrl.properGroupOrder = function properOrder(a, b) {
      return (a.value.split(' ')[0]).localeCompare(getLastName(b.value.split(' ')[0]),'en',{numeric:true});
    };

    ctrl.getStyleClass = function (columnName) {
      if(!namedColumns || !columnName){
        return;
      }
      if(namedColumns.indexOf(columnName)>-1){
        return 'xp-report-cell'
      } else {
        return 'xp-report-tiny-cell xp-report-small-box';
      }
    };

    ctrl.goToPastExperience = function (expId) {
      var expUrl = '/experience/' + expId + '/dashboard/responses';
      var query = {
        student_id: ctrl.filterStudent.user_id,
        concept_id: ctrl.filterConcept.id,
        type_id: ctrl.filterType.id,
        class_id: ctrl.filterClass.id,
        show_suggested: ctrl.showSuggested ? 'yes' : 'no'
      };
      ExperienceNavigator.navigateToExperience(expUrl, "", $location.path(), query);
    };

    ctrl.columns = [];
    ctrl.summaries = {};
    ctrl.summariesData = [];
    ctrl.standardExpectations = [];
    ctrl.expectationCodes = {};

    ctrl.classes = [{id:'none',name:'No Value'}];
    ctrl.filterClass = ctrl.classes[0];

    ctrl.types = [{id:'none',name:'No Value'}];
    ctrl.filterType = ctrl.types[0];

    ctrl.concepts = [{id:'none',name:'No Value'}];
    ctrl.filterConcept = ctrl.concepts[0];

    ctrl.students = [{id:'none',name:'No Value'}];
    ctrl.filterStudent = ctrl.students[0];

    ctrl.classTeacherName = 'No Value';

    ctrl.showSuggested = false;

    ctrl.filterYears = ReportingSchoolYearsService.get();
    ctrl.year = ctrl.filterYears[0].value;
    ctrl.filterYear = ctrl.filterYears[0];

    ctrl.toggleYear = function toggleYear(selection) {
      ctrl.year = selection.value;
      ctrl.filterYear = selection;
      ctrl.includeArchived = selection.archived;
      fetchAndUpdateReport();
    };

    ctrl.toggleSuggested = function toggleSuggested() {
      return updateReport(ctrl);
    };

    ctrl.setClass = function setClass(selection) {
      if(!selection || !selection.id){
        ctrl.filterClass = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.useReloadedRecords  = false;
      ctrl.filterStudent = ctrl.students[0];
      ctrl.filterClass = selection;
      ctrl.students = getListOfStudents(ctrl.reportData, ctrl.filterClass.id);
      return updateReport(ctrl);
    };

    ctrl.setType = function setType(selection) {
      if(!selection || !selection.id){
        ctrl.filterType = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.filterType = selection;
      return updateReport(ctrl);
    };

    ctrl.setConcept = function setConcept(selection) {
      if(!selection || !selection.id){
        ctrl.filterConcept = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.filterConcept = selection;
      ctrl.types = filterTypes(ctrl.reportData, ctrl.filterConcept.id);
      ctrl.filterType = ctrl.types[0];
      return updateReport(ctrl);
    };

    ctrl.exportAsCSV = function () {
      return exportAsCSV(ctrl.data, ctrl.columns);
    };

    ctrl.classReportDrilldown = function classReportDrilldown(teacherId, classId) {
      if(!teacherId || !classId){
        return false;
      }
      $location.search('teacher_id',teacherId);
      $location.search('class_id',classId);
      $location.search('concept_id',ctrl.filterConcept.id);
      $location.search('type_id',ctrl.filterType.id);
      $location.search('class_id',classId);
      $location.search('drilldown','yes');
    };

    ctrl.data = [];
    ctrl.reportData = [];
    ctrl.useReloadedRecords = false;
    ctrl.reloadedData = [];
    ctrl.reloadeSummariesdData = [];
    ctrl.tableParams = new NgTableParams(
      {
        count: 12,
        group: 'standard'
      },
      {
        dataset: [],
        counts: [],
      }
    );

    function getFilter() {
      var filter = {};
      var query = $location.search();
      filter.class_id = parseInt(query.class_id,10) || (ctrl.filterClass || {}).id;
      filter.include_archived = query.include_archived || ctrl.includeArchived;
      filter.year = query.year || ctrl.year;
      filter.teacher_id = parseInt(query.teacher_id,10);
      filter.type_id = parseInt(query.type_id, 10) || (ctrl.filterType || {}).id;
      filter.concept_id = parseInt(query.concept_id, 10) || (ctrl.filterConcept || {}).id;
      filter.drilldown = true;
      filter.show_suggested = query.show_suggested;
      filter.student_id = parseInt(query.student_id, 10) || (ctrl.filterStudent || {}).user_id ||'all';
      return filter;
    }

    function fillFiltersAndData(ctrl,filter,reportData, summaries) {
      ctrl.reportData = reportData;
      ctrl.summariesData = summaries;
      if (ctrl.reportData.length > 0) {
        ctrl.concepts = filterConcepts(ctrl.reportData);
        ctrl.filterConcept = ctrl.concepts[0]||{};
        ctrl.types = filterTypes(ctrl.reportData, ctrl.filterConcept.id);
        ctrl.filterType = ctrl.types[0]||{};
      
        ctrl.classes = generateUniqueClassList(reportData).filter(function removeEmptyRecs(rec) {
          return rec.name || rec.id === 'none';
        });
      }

      if(filter.class_id) {
        ctrl.filterClass = ctrl.classes.filter(function (rec) {
          return rec.id === filter.class_id;
        })[0] || ctrl.classes[0];
      }
      if (ctrl.reportData.length > 0) {
        if(!filter.student_id || filter.student_id === 'all') {
          ctrl.students = getListOfStudents(ctrl.reportData, ctrl.filterClass.id);
        }
        ctrl.filterStudent = ctrl.students[0];
      }

      if(filter.concept_id) {
        ctrl.filterConcept = ctrl.concepts.filter(function (rec) {
          return rec.id === filter.concept_id;
        })[0] || ctrl.concepts[0];
      }
      if(filter.type_id && ctrl.reportData.length > 0) {
        ctrl.types = filterTypes(ctrl.reportData, ctrl.filterConcept.id);
        ctrl.filterType = ctrl.types.filter(function (rec) {
          return rec.id === filter.type_id;
        })[0] || ctrl.types[0];
      }
      if(filter.student_id && filter.student_id !=='all'){
        ctrl.filterStudent = ctrl.students.filter(function (std) {
          return std.user_id === filter.student_id;
        });
      }

      if (filter.year) {
        ctrl.filterYear = ctrl.filterYears.filter(function(year) {
          return year.value === parseInt(filter.year, 10);
        })[0] || ctrl.filterYears[0];
      }
    }

    function fetchAndUpdateReport(init) {
      var filter = getFilter();
      if(init){
        filter.student_id = 'all';
      }

      return ActiveMode.getReportData(filter).then(function (report) {
        ctrl.labels = report.labels || ctrl.labels;
        ctrl.parent.title = (report.name || '').replace('TEKS', ctrl.getLabel('label-standard-name', 'TEKS'));
        ctrl.hasTypes = !noTypeReportsList.includes(report.report_kind);
        fillFiltersAndData(ctrl, filter, report.data.records, report.data.summaries);

        if (report.data.teacher_name) {
          ctrl.classTeacherName = report.data.teacher_name;
        }

        updateReport(ctrl);
      });
    }

    ctrl.setStudent = function setStudent(selection) {
      if(!selection || !selection.user_id || selection.user_id === 'all'){
        ctrl.filterStudent = ctrl.students[0];
        ctrl.useReloadedRecords = false;
        return updateReport(ctrl);
      }

      ctrl.useReloadedRecords = true;
      ctrl.filterStudent = (ctrl.students).filter(function (std) {
        return std.user_id === selection.user_id;
      })[0];

      var filter = getFilter();
      filter.student_id = selection.user_id;

      return ActiveMode.getReportData(filter).then(function (report) {
        ctrl.labels = report.labels || ctrl.labels;
        ctrl.parent.title = (report.name || '').replace('TEKS', ctrl.getLabel('label-standard-name', 'TEKS'));
        ctrl.reloadedData = report.data.records;
        ctrl.reloadeSummariesdData = report.data.summaries;
        updateReport(ctrl);
      });
    };

    ctrl.inited = false;

    var preFilter = getFilter();
    ctrl.showSuggested = preFilter.show_suggested === 'yes';
    fetchAndUpdateReport(true)
      .then(function () {
        if(preFilter.student_id && preFilter.student_id !=='all'){
          return ctrl.setStudent(preFilter.student_id);
        }
      })
      .then(function () {
        ctrl.inited = true;
      })
      .catch(function(error) {
        $log.error("error in updating report:",error);
        ctrl.inited = true;
      });

    ctrl.formatDate = function formatDate(endsAt) {
      return window.moment(endsAt).format('M/DD/YY');
    };
  }

  module.component('curriculumTeksDetails', {
    require: {parent: '^^xpReport'},
    template: require('./curriculumTEKSDetails.jade'),
    controller: controller,
  });

})();
