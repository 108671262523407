'use strict';

(function () {

  var module = angular.module('client.components');

  controller.$inject = ['$location', 'ActiveMode', 'NgTableParams', 'reportLabels', 'ReportingSchoolYearsService',
                        'noTypeReportsList', '$log', 'ReportNavigation', 'User', 'UserInfoDefaultFactory', 'teksService'];

  function controller($location, ActiveMode, NgTableParams, reportLabels, ReportingSchoolYearsService,
                      noTypeReportsList, $log, ReportNavigation, User, UserInfoDefaultFactory, teksService) {

    var ctrl = this;

    ctrl.inited = false;
    ctrl.isInDrilldown = false;

    ctrl.adminReport = {};
    ctrl.filterYears = ReportingSchoolYearsService.get();
    ctrl.year = ctrl.filterYears[0].value;
    ctrl.filterYear = ctrl.filterYears[0];

    ctrl.getLabel = new reportLabels('labels', ctrl);

    ctrl.classes = [{id:'none',name:'No Value'}];
    ctrl.filterClass = ctrl.classes[0];

    ctrl.filterType = {id:null};
    ctrl.filterConcept = {id:null};


    function getFilter() {
      var filter = {};
      var query = $location.search();
      filter.class_id = parseInt(query.class_id,10);
      filter.include_archived = query.include_archived || ctrl.includeArchived;
      filter.year = query.year || ctrl.year;
      filter.teacher_id = parseInt(query.teacher_id,10);
      filter.type_id = parseInt(query.type_id, 10);
      filter.drilldown = query.drilldown || false;
      filter.metric_id = query.metric_id;
      filter.studentsummary = query.studentsummary === 'yes';
      return filter;
    }

    var filter = getFilter();
    if (filter.teacher_id) {
      UserInfoDefaultFactory.get({}, {'id': filter.teacher_id}).$promise
      .then(function (response) {
        ctrl.classTeacherName = response.first_name + " " + response.last_name;
      });
    }
    else {
      ctrl.classTeacherName = User.getCurrentUser().first_name + " " + User.getCurrentUser().last_name;
    }

    ctrl.metrics = [
      {name:'Pre Test Scores', id:'pretest'},
      {name:'Post Test Scores', id:'posttest'},
      {name: '', id: 'none', status: 'DIVIDER'},
      {name:'Average Quiz Item Score', id:'quiz'},
      {name:'Total Responses', id:'responses'},
      {name:'Time on Standard (min.)', id:'time_on_standard', label: 'label-summary-metric-time-on-standard', label_default:'Time on Standard (min.)'},
      ];
    ctrl.filterMetricName = ctrl.metrics[0].name;
    ctrl.filterMetric = ctrl.metrics[0].id;

    ctrl.setMetric = function setMetric(selection){
      ctrl.filterMetricName = selection.name;
      ctrl.filterMetric = selection.id;
      teksService.setColumns(ctrl, ctrl.standardExpectations, selection.id, ctrl.standardExpectationLabels);
    };

    ctrl.setClass = function setClass(selection) {
      if(!selection || !selection.id){
        ctrl.filterClass = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.filterClass = selection;
      return teksService.updateReport(ctrl);
    };

    ctrl.toggleYear = function toggleYear(selection) {
      ctrl.year = selection.value;
      ctrl.filterYear = selection;
      ctrl.includeArchived = selection.archived;
      fetchAndUpdateReport();
    };

    ctrl.columns = [];
    ctrl.standardExpectations = [];
    ctrl.expectationCodes = {};

    function fetchAndUpdateReport() {
      var filter = getFilter();
      filter.include_archived = ctrl.includeArchived;
      var isInDrilldown = filter.studentsummary;

      ctrl.isInDrilldown = isInDrilldown;

      return ActiveMode.getReportData(filter).then(function (report) {
        ctrl.parent.title = (report.name || '').replace('TEKS', ctrl.getLabel('label-standard-name', 'TEKS'));
        ctrl.adminReport.id = report.report_id;
        ctrl.currentLevel = report.level;
        if(isInDrilldown){
          ctrl.currentLevel = teksService.LEVELS.CURRICULUM;
        }
        ctrl.labels = report.labels;
        ctrl.hasTypes = !noTypeReportsList.includes(report.report_kind);

        ctrl.metrics.forEach(function (metric) {
          if (metric.label){
            metric.name = ctrl.getLabel(metric.label, metric.label_default);
          }
        });

        ctrl.reportData = report.data.records;
        ctrl.expectationCodes = report.data.short_expectation_codes||{};
        ctrl.tiaTestScores = report.data.tiaTestScores;
        ctrl.tableParams = new NgTableParams(
          {
            // items per page
            count: 12,
            sorting: { filterDate: "desc" }
          },
          {
            dataset: [],
            // options for page size
            counts: [],
          }
        );

        if (filter.year) {
          ctrl.filterYear = ctrl.filterYears.filter(function(year) {
            return year.value === parseInt(filter.year, 10);
          })[0] || ctrl.filterYears[0];
        }

        if(filter.metric_id){
          ctrl.filterMetric = ctrl.metrics.filter(function (rec) {
            return rec.id === filter.metric_id;
          }).map(function (rec) {
            return rec.id;
          })[0] || ctrl.metrics[0].id;

          ctrl.filterMetricName = ctrl.metrics.filter(function (rec) {
            return rec.id === ctrl.filterMetric;
          })[0].name;
        }

        ctrl.allowArchived = teksService.isDistrictReport(ctrl);

        if (teksService.isCurriculumReport(ctrl) && report.data.records.length > 0) {
          ctrl.allowArchived = false;
          ctrl.classes = teksService.generateUniqueClassList(report.data.records);
          ctrl.filterClass = ctrl.classes[0];

          if(isInDrilldown){
            ctrl.filterClass = ctrl.classes.filter(function (rec) {
              return rec.id === filter.class_id;
            })[0];
          }

          var firstRecord = report.data.records[0] || {};
          if(firstRecord.teacher_first_name || firstRecord.teacher_last_name){
            ctrl.classTeacherName = firstRecord.teacher_first_name + ' ' + firstRecord.teacher_last_name;
          }
        }

        if(!report.data.short_expectation_codes){
          ctrl.data = [];
          return;
        }

        teksService.updateReport(ctrl);
        return report;
      });
    }

    fetchAndUpdateReport().then(function () {
      ctrl.inited = true;
    })
    .catch(function(error) {
      $log.error("error in updating report:",error);
      ctrl.inited = true;
    });
  }

  module.component('curriculumTeksTiaSummary', {
    require: {parent: '^^xpReport'},
    template: require('./curriculumTIASummary.jade'),
    controller: controller,
  });

})();