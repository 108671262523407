'use strict';

angular.module('client.services').factory('standardsService', ['$resource',
  function ($resource) {

  var templateExpectationsResource =
    $resource('/main/v1' + '/experience_templates/:template_id/set/:set_id/expectations', {},
      {get: {method: 'GET', isArray: true, params: {template_id: '@template_id', set_id: '@set_id'}}});

  var templateBreakoutsResource =
    $resource('/main/v1' + '/experience_templates/:id/breakouts', {},
        {get: {method: 'GET', isArray: true, params: {id: '@id'}}});

  var templateSceneExpectationsResource =
    $resource('/main/v1' + '/experience_templates/:template_id/set/:set_id/scene_expectations', {},
        {get: {method: 'GET', params: {template_id: '@template_id', set_id: '@set_id'}}});


  function templateExpectations(template_id, set_id) {
    return templateExpectationsResource.get({template_id: template_id, set_id: set_id});
  }

  function templateBreakouts(id) {
    return templateBreakoutsResource.get({id: id});
  }

  function sceneExpectations(template_id, set_id) {
    return templateSceneExpectationsResource.get({template_id: template_id, set_id: set_id});
  }

  return {
    templateExpectations: templateExpectations,
    templateBreakouts: templateBreakouts,
    sceneExpectations: sceneExpectations
  };
}]);
