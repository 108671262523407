'use strict';

angular.module('client.services').factory('QuizStateService', ['$resource',
                            function ($resource) {
  return {
    getUserQuizElementState: function(experienceId, userId) {
      return $resource('/main/v1/' + 'experiences/:experience_id/users/:user_id/state/quiz', {}, {
        getState: {method: 'POST', isArray: true, params: {experience_id: experienceId, user_id: userId}}
      });
    },
    getQuizElementState: function(experienceId) {
      return $resource('/main/v1/' + 'experiences/:experience_id/state/quiz', {}, {
        getState: {method: 'POST', isArray: true, params: {experience_id: experienceId}}
      });
    },
    quizUserState: function(experienceId, quizId, userId) {
      return $resource('/main/v1/' + 'experiences/:id/quizzes/:qid/users/:uid/state', {}, {
        saveState: {method: 'POST', params: {id: experienceId, qid: quizId, uid: userId}},
        getState: {method: 'GET', params: {id: experienceId, qid: quizId, uid: userId}},
        retake: {method: 'POST', params: {id: experienceId, qid: quizId, uid: userId}},
      });
    },
    quizState: function(experienceId, quizId) {
      return $resource('/main/v1/' + 'experiences/:id/quizzes/:qid/state', {}, {
        getState: {method: 'GET', isArray: true, params: {id: experienceId, qid: quizId}}
      });
    },
    quizActivate: function(experienceId, quizId) {
      return $resource('/main/v1/' + 'experiences/:id/quizzes/:qid/reactivate', {}, {
        reactivate: {method: 'POST', params: {id: experienceId, qid: quizId}}
      });
    }
  }
}]);

angular.module('client.services').factory('TIAControlledRealtimeService', ['$log', 'WebSocket', function ($log, WebSocket) {
  $log.debug('Initializing TIAControlledRealtimeService.');

  return {
    EVENTS: Object.freeze({TeacherControlledEvent: 'teacher_control'}),
    on: function (type, fn) {
      WebSocket.on(type, fn);
    },

    removeListener: function (type, listener) {
      WebSocket.removeListener(type, listener);
    }
  };
}]);

