'use strict';

angular.module('client.services').factory('reportHelperService', ['$resource',
  function ($resource) {
  
  function abbreviateNumber(num) {
    if (num === undefined || num === null) {
      return '';
    }
    if (num < 1000) {
      return num;
    }
    var power = ((num).toPrecision(2).split("e")[1] || [0, 0]).slice(1);
    var triplets = Math.floor(power / 3);
    var numericResult = (num / Math.pow(10, triplets * 3)).toFixed(1);
    if (numericResult.length > 4) {
      numericResult = (num / Math.pow(10, triplets * 3)).toFixed();
    }
    return numericResult + ['', 'K', 'M', 'B', 'T'][triplets];
  }

  return {
    abbreviateNumber: abbreviateNumber
  };
}]);
