'use strict';
(function () {
  var module = angular.module('xp-element-spire-common', ['angularWidget', 'client.services', 'element-templates', 'monospaced.elastic']);

  module.run(function ($http, $compile, $templateCache) {
    if (!$templateCache.get('xpSpireTestTemplate.tpl')) {
      $compile(require('./template.jade'));
    }
  });

  module.factory('xpSpireConcepts', ['$resource', function($resource) {
    var parsedData = {};

    $resource('/main/v1' + '/data/spire/allConcepts').query({}, function (value) {
      parsedData = parseData(value);
    });

    function parseData(data) {
      var retval = {};

      data.sort(function(a, b) {
        return a.concept - b.concept;
      });

      data.forEach(function (row) {
        retval[row.level] = retval[row.level] || {};
        retval[row.level][row.lesson] = retval[row.level][row.lesson] || [];
        retval[row.level][row.lesson].push(row.concept);
      });

      return retval;
    }

    function getLessonConcepts(level, lesson) {
      if (parsedData[level]) return parsedData[level][lesson];

      return [];
    }

    return getLessonConcepts;
  }]);

  module.directive('xpModel', ['$parse', function ($parse) {
    return {
      restrict: 'A',
      scope: {xpModel: '='},
      link: link
    };

    function link(scope, element, attrs) {
      scope.$parent.xpModel = scope.xpModel;
    }
  }]);

  module.directive('xpSpireTeacherPhrase', function () {
    return {
      restrict: 'E',
      scope: {
        phrase: '=',
        xpClick: '&'
      },
      templateUrl: 'xpSpireTeacherWordPhraseDirective.tpl',
      link: link
    };

    function link(scope, element, attrs) {
      scope.onClickHandler = function (event) {
        if (scope.xpClick !== undefined) {
          var func = scope.xpClick();
          if (angular.isFunction(func))
            func(scope.phrase);
        }
      };
    }
  });

  module.directive('xpSpireTeacherWords', function () {
    return {
      restrict: 'E',
      scope: {
        phrases: '=',
        phraseClick: '&',
        lastPhrase: '&'
      },
      templateUrl: 'xpSpireTeacherWordsDirective.tpl'
    };
  });

  module.directive('xpSpireStudentWords', function () {
    return {
      restrict: 'E',
      scope: {
        phrases: '=',
      },
      templateUrl: 'xpSpireStudentWordsDirective.tpl'
    };
  });

  module.directive('xpSpireTeacherSentences', function () {
    return {
      restrict: 'AE',
      scope: {
        sentences: '=',
        phraseClick: '&'
      },
      templateUrl: 'xpSpireTeacherSentencesDirective.tpl'
    };
  });

  module.directive('xpSpireUsers', function () {
    return {
      restrict: 'E',
      templateUrl: 'xpSpireUsersDirective.tpl',
    };
  });

  module.directive('xpSpireTimer', ['$timeout', function ($timeout) {
    return {
      restrict: 'E',
      scope: {
        duration: '=',
        enabled: '=',
        showTimerButton: '=',
        onBegin: '&', // Note: Due to stupid Angular issue, this can't be called onStart
        onFinish: '&', // Note: Due to stupid Angular issue, this can't be called onEnd
        onCancel: '&',
        registerReset: '&'
      },
      templateUrl: 'xpSpireTimerDirective.tpl',
      compile: compile
    };

    function compile(element, attrs) {
      if (angular.isUndefined(attrs.showTimerButton)) {
        attrs.showTimerButton = 'true';
      }

      return {
        post: link
      };
    }

    function link(scope, element, attrs) {
      var _duration = 60;
      if (!angular.isUndefined(scope.duration) && scope.duration !== null)
        _duration = scope.duration;

      if (canCall(scope.registerReset)) {
        scope.registerReset()(resetDirective);
      }

      var _timerRunning = false;

      function resetDirective() {
        _timerRunning = false;
        scope.seconds = _duration;
      }

      element.on('$destroy', function () {
        resetDirective();
      });

      resetDirective();

      scope.isEnabled = function () {
        return true;
      };

      scope.onTimerButtonClick = function () {
        if (!scope.isEnabled())
          return;

        if (timerIsRunning()) {
          setTimerRunning(false);
          tryCall(scope.onCancel);
        }
        else {
          startTimer();
        }
      };

      function timerIsRunning() {
        return _timerRunning;
      }

      scope.timerIsRunning = timerIsRunning;

      function setTimerRunning(value) {
        _timerRunning = value;
      }

      function canCall(value) {
        return !angular.isUndefined(value) && value !== null && !angular.isUndefined(value()) && value() !== null;
      }

      function tryCall(value) {
        if (canCall(value))
          return value()();
        return true;
      }

      function startTimer() {
        if (!tryCall(scope.onBegin))
          return;

        setTimerRunning(true);
        var startTime = Date.now();
        var startSeconds = scope.seconds;

        var onTimeout = function () {
          if (timerIsRunning()) {
            var elapsedTime = Math.floor((Date.now() - startTime) / 1000);

            if (elapsedTime >= startSeconds) {
              scope.seconds = 0;
              $timeout.cancel(timer);
              tryCall(scope.onFinish);
            }
            else {
              scope.seconds = Math.max(0, startSeconds - elapsedTime);
              $timeout(onTimeout, 200);
            }
          }
          else {
            $timeout.cancel(timer);
          }
        };

        var timer = $timeout(onTimeout, 200);
      }
    }
  }]);

  function Phrase(text, isKeyword) {
    this.text = text;
    this.isKeyword = isKeyword;
    this.isCorrect = true;
    this.isLast = false;
  }

  function TimedReadingModel() {
    var _phrases = [];
    var _lastPhrase = null;
    var _lastPhraseIndex = null;
    var _editing = false;
    var _markingLastPhrase = false;
    var _wordsPerMinute = null;
    var _response = null;
    var _timerIsRunning = false;

    this.reset = function () {
      _phrases.length = 0;
      _lastPhrase = null;
      _lastPhraseIndex = null;
      _editing = false;
      _markingLastPhrase = false;
      _wordsPerMinute = null;
      _response = null;
      _timerIsRunning = false;
    };

    this.setPhrases = function (value) {
      _phrases.length = 0;
      if (value && value.forEach) {
        value.forEach(function (val) {
          _phrases.push(val);
        });
      }
    };

    this.getPhrases = function () {
      return _phrases;
    };

    this.setEditing = function (value) {
      _editing = value;
    };
    this.isEditing = function () {
      return _editing;
    };

    this.setMarkingLastPhrase = function (value) {
      _markingLastPhrase = value;
    };
    this.isMarkingLastPhrase = function () {
      return _markingLastPhrase;
    };

    this.isEditingExisting = function () {
      return this.isEditing() && getLastPhrase() !== null;
    };

    function setWordsPerMinute(value) {
      _wordsPerMinute = value;
    }

    this.getWordsPerMinute = function () {
      return _wordsPerMinute;
    };

    this.setTimerIsRunning = function (value) {
      _timerIsRunning = value;
    };

    this.isTimerRunning = function() {return _timerIsRunning;};

    this.clearResponse = function () {
    }; //Just a stub to be replaced by the controller. Not ideal. TODO

    this.setResponse = function (response) {
      _response = response;

      clearResponse();
      if (!angular.isUndefined(response) && response !== null) {
        if ((response.incorrect && response.incorrect.length) || (response.lastWordIndex !== null && !angular.isUndefined(response.lastWordIndex))) {
          var phraseIndex = 0;
          var incorrectIndex = 0;
          forAllPhrases(function (phrase) {
            if (phrase.isKeyword) {
              phrase.isCorrect = true;
              phrase.isLast = false;
              if (incorrectIndex < response.incorrect.length) {
                if (phraseIndex == response.incorrect[incorrectIndex]) {
                  phrase.isCorrect = false;
                  ++incorrectIndex;
                }
              }

              if (phraseIndex === response.lastWordIndex) {
                setLastPhrase(phrase);
                setLastPhraseIndex(phraseIndex);
                phrase.isLast = true;
              }

              ++phraseIndex;
            }
          });
        }
      }

      updateScore();
    };

    this.togglePhrase = function (phrase) {
      if (!this.isEditing())
        return;

      var index = 0;
      if (this.isMarkingLastPhrase()) {
        var lastPhrase = null;
        var lastPhraseIndex = null;
        var lastIncorrectIndex = null;
        forAllPhrases(function (value) {
          if (phrase === value) {
            lastPhrase = phrase;
            lastPhraseIndex = index;
          }

          if (!value.isCorrect) {
            lastIncorrectIndex = index;
          }

          if (value.isKeyword)
            ++index;
        });

        // Only allow marking of phrases beyond the last incorrect marked one.
        if (lastIncorrectIndex === null || lastPhraseIndex >= lastIncorrectIndex) {
          if (getLastPhrase() !== null) {
            getLastPhrase().isLast = false;
          }
          setLastPhrase(lastPhrase);
          setLastPhraseIndex(lastPhraseIndex);
          lastPhrase.isLast = true;
        }
      }
      else {
        if (getLastPhraseIndex() !== null) {
          var phraseIndex = 0;
          forAllPhrases(function (value) {
            if (value === phrase) {
              phraseIndex = index;
            }

            if (value.isKeyword)
              ++index;
          });

          // Don't allow marking words past marked last word.
          if (phraseIndex > getLastPhraseIndex()) {
            return;
          }
        }

        phrase.isCorrect = !phrase.isCorrect;
      }
      updateScore();
    };

    this.getResponse = function () {
      var incorrectPhrases = getIncorrectPhraseIndexes();
      var response =
      {
          "correct": this.getWordsPerMinute(),
          "possible": this.getPhrases().length,
          "incorrect": incorrectPhrases,
          "lastWordIndex": _lastPhraseIndex
      };
      return response;
    };

    this.isModified = function () {
      return arePhrasesModified() || isLastPhraseModified() || this.isTimerRunning();
    };

    function setLastPhrase(value) {
      _lastPhrase = value;
    }

    function getLastPhrase() {
      return _lastPhrase;
    }

    function setLastPhraseIndex(value) {
      _lastPhraseIndex = value;
    }

    function getLastPhraseIndex() {
      return _lastPhraseIndex;
    }

    function forAllPhrases(func) {
      _phrases.forEach(func);
    }

    function clearResponse() {
      clearPhrases();
      clearLastPhrase();
      updateScore();
    }

    function updateScore() {
      if (getLastPhrase() === null) {
        setWordsPerMinute(null);
      }
      else {
        var correctCount = 0;
        var index = 0;
        forAllPhrases(function (phrase) {
          correctCount += (index <= getLastPhraseIndex() && phrase.isKeyword && phrase.isCorrect);
          if (phrase.isKeyword)
            ++index;
        });
        setWordsPerMinute(correctCount);
      }
    }

    function clearPhrases() {
      forAllPhrases(function (phrase) {
        phrase.isCorrect = true;
      });
    }

    function clearLastPhrase() {
      var lastPhrase = getLastPhrase();
      if (lastPhrase !== null)
        lastPhrase.isLast = false;
      setLastPhrase(null);
      setLastPhraseIndex(null);
    }

    function getIncorrectPhraseIndexes() {
      var phraseIndex = 0;
      var incorrectPhrases = [];

      forAllPhrases(function (phrase) {
        if (phrase.isKeyword) {
          if (!phrase.isCorrect) {
            incorrectPhrases.push(phraseIndex);
          }
          ++phraseIndex;
        }
      });

      return incorrectPhrases;
    }

    function arePhrasesModified() {
      var incorrectPhrases = getIncorrectPhraseIndexes();
      if (!angular.isUndefined(_response) && _response !== null) {
        return incorrectPhrases.toString() != _response.incorrect.toString();
      }

      return incorrectPhrases.length > 0;
    }

    function isLastPhraseModified() {
      if (!angular.isUndefined(_response) && _response !== null) {
        return _response.lastWordIndex !== _lastPhraseIndex;
      }

      return _lastPhraseIndex !== null;
    }
  }

  function ReadingModel() {
    var _phrases = [];
    var _score = 0;
    var _editing = false;
    var _response = null;

    this.reset = function () {
      _phrases.length = 0;
      _score = 0;
      _editing = false;
      _response = null;
    };

    this.setPhrases = function (value) {
      _phrases.length = 0;
      if (value && value.forEach) {
        value.forEach(function (val) {
          _phrases.push(val);
        });
      }
    };

    this.getPhrases = function () {
      return _phrases;
    };

    this.getScore = function () {
      return _score;
    };
    this.getPossible = function () {
      return _phrases.length;
    };
    this.getPercent = function () {
      return Math.round((this.getScore() / this.getPossible()) * 100);
    };

    this.setEditing = function (value) {
      _editing = value;
    };
    this.isEditing = function () {
      return _editing;
    };

    this.clearResponse = function () {
    }; //Just a stub to be replaced by the controller. Not ideal. TODO

    this.setResponse = function (response) {
      _response = response;

      clearResponse();
      if (!angular.isUndefined(response) && response !== null) {
        if (response.incorrect && response.incorrect.length) {
          var phraseIndex = 0;
          var incorrectIndex = 0;
          forAllPhrases(function (phrase) {
            if (phrase.isKeyword) {
              phrase.isCorrect = true;
              phrase.isLast = false;
              if (incorrectIndex < response.incorrect.length) {
                if (phraseIndex == response.incorrect[incorrectIndex]) {
                  phrase.isCorrect = false;
                  ++incorrectIndex;
                }
              }
              ++phraseIndex;
            }
          });
        }
      }

      updateScore();
    };

    this.togglePhrase = function (phrase) {
      if (!this.isEditing() || !phrase.isKeyword)
        return;

      phrase.isCorrect = !phrase.isCorrect;
      updateScore();
    };

    this.getResponse = function () {
      var incorrectPhrases = getIncorrectPhraseIndexes();
      var response =
      {
        "correct": this.getScore(),
        "possible": this.getPossible(),
        "incorrect": incorrectPhrases,
      };
      return response;
    };

    this.isModified = function () {
      return arePhrasesModified();
    };

    function forAllPhrases(func) {
      _phrases.forEach(func);
    }

    function clearResponse() {
      clearPhrases();
      updateScore();
    }

    function updateScore() {
      var correctCount = 0;
      var index = 0;
      forAllPhrases(function (phrase) {
        correctCount += (phrase.isKeyword && phrase.isCorrect);
        if (phrase.isKeyword)
          ++index;
      });
      _score = correctCount;
    }

    function clearPhrases() {
      forAllPhrases(function (phrase) {
        phrase.isCorrect = true;
      });
    }

    function getIncorrectPhraseIndexes() {
      var phraseIndex = 0;
      var incorrectPhrases = [];

      forAllPhrases(function (phrase) {
        if (phrase.isKeyword) {
          if (!phrase.isCorrect) {
            incorrectPhrases.push(phraseIndex);
          }
          ++phraseIndex;
        }
      });

      return incorrectPhrases;
    }

    function arePhrasesModified() {
      var incorrectPhrases = getIncorrectPhraseIndexes();
      if (!angular.isUndefined(_response) && _response !== null) {
        return incorrectPhrases.toString() != _response.incorrect.toString();
      }

      return incorrectPhrases.length > 0;
    }
  }

  function parseWords(value) {
    var retval = [];
    if (value && value.split) {
      var split = value.split(',');
      split.forEach(function (value) {
        var text = value.trim();
        if (text.length > 0) {
          retval.push(new Phrase(text, true));
        }
      });
    }
    return retval;
  }

  module.factory('spire', ['ModalService', 'ElementsRestService', 'ElementsErrorService',
                           'widgetConfig', 'JSONStringUtility', '$log', 'IconOverlays',
  function (ModalService, ElementsRestService, ElementsErrorService,
            widgetConfig, JSONStringUtility, $log, IconOverlays) {
    function displayChangeWarningDialog(continueFunction) {
      ModalService.show(
          {
            title: 'Results Modified',
            message: 'Do you want to continue and clear current results?',
            buttons: [{
              title: 'Continue',
              click: 'continue(); $hide();'},
              {
                title: 'Cancel',
                click: '$hide()'
              }],
            "continue": continueFunction
          }
      );
    }

    function configureScope($scope, parseElement, displayUserResponses, isResponseModified, elementName) {
      var DISPLAY_MODE = Object.freeze({kTeacherMode: 0, kStudentMode: 1});
      $scope.DISPLAY_MODE = DISPLAY_MODE;
      resetElement($scope);
      $scope.options = widgetConfig.getOptions($scope);

      $scope.$watch('options', function () {
        resetElement($scope);

        var options = $scope.options;
        if (!options.element)
          return;

        var service = options.elementRealtimeService;
        var EVENTS = service.EVENTS;
        var context = options.context;
        parseElement();

        $scope.isTeacher = context.userIsTeacher();
        $scope.displayMode = context.userIsTeacher() ? $scope.DISPLAY_MODE.kTeacherMode : $scope.DISPLAY_MODE.kStudentMode;

        if ($scope.isTeacher) {
          $scope.respondents = [];

          if ($scope.filteredStudent !== null && !angular.isUndefined($scope.filteredStudent)) {
            $scope.respondents.push($scope.filteredStudent.uid);
          } else {
            $scope.respondents.push(context.userId);
            context.clazz.students.forEach(function (student) {
              $scope.respondents.push(student.uid);
            });
          }

          $scope.teacher = context.clazz.userWithId(context.userId);

          $scope.$on('pageElementsLoaded', function(){
            $scope.elementDisplayed = true;
          });

          displayUserResponses();

          loadAnswers(true);
        }
        else {
          // Notify the widget that were are done loading the data
          widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
        }
      }, true);

      function loadAnswers(firstTime) {
        var options = $scope.options;
        var context = options.context;
        if (!$scope.options.element.id || !context.userIsTeacher())
          return;

        var isInactive = $scope.options.context.getViewingInactiveExperience();

        ElementsRestService.getSharedState($scope.options.context.experienceId, $scope.options.element.id, $scope.options.context.groupName, isInactive,
            function (result) {
              if (result instanceof Array) {
                result.forEach(function (response) {
                  updateStudentResponseFromServer(response);
                });
              }

              if ($scope.filteredStudent !== null && !angular.isUndefined($scope.filteredStudent)) {
                context.clazz.students.forEach(function (student) {
                  if (student.uid === $scope.filteredStudent.uid) {
                    $scope.selectStudent(student.uid);
                  }
                });
              }

              if (firstTime) {
                var service = options.elementRealtimeService;
                var EVENTS = service.EVENTS;

                service.on(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
                $scope.$on('$destroy', function () {
                  service.removeListener(EVENTS.XPElementStateChangedNotification, stateChangedNotificationHandler);
                });

                // Notify the widget that were are done loading the data
                widgetConfig.exportProperties({elementId: $scope.options.element.id, readyToDisplay: true});
              }
            },
            function (error) {
              ElementsErrorService.error(error);
            });
      }

      function updateStudentResponseFromServer(response) {
        var data = JSONStringUtility.parse(response.user_data);

        if (data !== null) {
          $scope.studentResponses[response.user_id] = data;
        }
        else {
          $scope.studentResponses[response.user_id] = undefined;
        }
      }

      function stateChangedNotificationHandler(e) {
        var result = e.detail;
        var state = result.record;

        if (state.element_id != $scope.options.element.id)
          return;

        $log.debug("Received spire concept assessment student update: " + JSON.stringify(result));
        updateStudentResponseFromServer(state);
        if ($scope.selectedStudent !== null && !angular.isUndefined($scope.selectedStudent) && state.user_id === $scope.selectedStudent)
        {
          displayUserResponses($scope.selectedStudent);
        }
      }

      $scope.selectTeacher = function () {
        if (isResponseModified()) {
          displayChangeWarning(null);
        }
        else {
          changeSelectedUserAndUpdateDisplay(null);
        }
      };

      $scope.hasEditMenu = function () {
        return $scope.selectedStudent !== null && (!angular.isUndefined($scope.studentResponses[$scope.selectedStudent]));
      };

      $scope.selectStudent = function (student) {
        if (isResponseModified()) {
          displayChangeWarning(student);
        }
        else {
          changeSelectedUserAndUpdateDisplay(student);
        }
      };

      function changeSelectedUserAndUpdateDisplay(user) {
        $scope.selectedStudent = user;
        displayUserResponses(user);
      }

      function displayChangeWarning(student) {
        displayChangeWarningDialog(function () {
          changeSelectedUserAndUpdateDisplay(student);
        });
      }

      $scope.displayChangeWarning = displayChangeWarning;

      function displayDeleteWarning(deleteFunction) {
        ModalService.show(
          {
            title: 'Delete Results',
            message: 'Delete student ' + elementName + '?',
            buttons: [{
                title: 'Delete',
                click: 'deleteResponse(); $hide();'
              },
              {
                title: 'Cancel',
                click: '$hide()'
              }
              ],
              deleteResponse: deleteFunction
          }
        );
      }

      $scope.requestDelete = function () {
        if ($scope.selectedStudent === null || !$scope.isTeacher)
          return;
        displayDeleteWarning(function () {
          deleteStudentResponse();
        });
      };

      function deleteStudentResponse() {
        if ($scope.selectedStudent === null || !$scope.isTeacher)
          return;

        ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.selectedStudent, -1, JSON.stringify(null),
            function () {
          $scope.studentResponses[$scope.selectedStudent] = undefined;
          displayUserResponses($scope.selectedStudent);
        },
        function (error) {
          ElementsErrorService.error(error);
        });
      }

      $scope.wrapRespondent = function wrapRespondent(respondent) {
        var wrappedRespondent = new $scope.Respondent(respondent);

        wrappedRespondent.isSelected = function isSelected() {
          return ($scope.selectedStudent && respondent === $scope.selectedStudent) ||
                  ($scope.selectedStudent === null && $scope.teacher && respondent === $scope.teacher.uid);
        };

        wrappedRespondent.select = function select() {
          if ($scope.teacher && respondent === $scope.teacher.uid) {
            $scope.selectTeacher();
          } else {
            $scope.selectStudent(respondent);
          }
        };

        wrappedRespondent.getOverlay = function getOverlay() {
          return $scope.getStudentOverlay(respondent);
        };

        return wrappedRespondent;
      };

      $scope.getStudentOverlay = function getStudentOverlay(studentId)
      {
        if ($scope.studentResponses[studentId] !== null && !angular.isUndefined($scope.studentResponses[studentId])) {
          return IconOverlays.MINUS;
        }

        return undefined;
      };
    }

    function resetElement($scope) {
      $scope.teacher = {};
      $scope.isTeacher = false;
      $scope.selectedStudent = null;
      $scope.comment = {text: ''};
      $scope.studentResponses = {};
    }

    return {
      configureScope: configureScope,
      resetElement: resetElement,
      Phrase: Phrase,
      BREAK_PHRASE: new Phrase('', false),
      parseWords: parseWords,
      TimedReadingModel: TimedReadingModel,
      ReadingModel: ReadingModel
    };
  }]);

  module.controller('clientSpireTimedReadingCtrl', ['$scope', 'ModalService', 'spire', function ($scope, ModalService, spire) {
    var model = null;
    $scope.timerDuration = 60;

    $scope.$watch('xpModel', function () {
      model = $scope.xpModel;
      $scope.words = model.getPhrases();
      var innerClearResponse = model.clearResponse;
      model.clearResponse = function () {
        clearResponse();
        innerClearResponse();
      };
    }, true);


    function clearResponse() {
      model.setResponse(null);
      model.setTimerIsRunning(false);
      setMarkingLast(false);
      resetTimer();
    }

    function isEditing() {
      return model.isEditing();
    }

    function isMarkingLast() {
      return model.isMarkingLastPhrase();
    }

    $scope.onPhraseClick = function (phrase) {
      if (model.isTimerRunning() || model.isEditingExisting() || isMarkingLast()) {
        model.togglePhrase(phrase);
      }
    };

    $scope.onTimerCancel = function () {
      model.setTimerIsRunning(false);
      requestClearAnswers();
    };

    function setMarkingLast(value) {
      model.setMarkingLastPhrase(value);
    }

    function displayClearAnswersAlert(clearAnswersFunction, keepAnswersFunction) {
      ModalService.show(
          {
            title: 'Clear Answers',
            message: 'Clear the student answers?',
            buttons: [{
                  title: 'Clear',
                  click: 'clearAnswers(); $hide();'
                },{
                  title: 'Keep',
                  click: 'keepAnswers(); $hide();'
                }],
            clearAnswers: clearAnswersFunction,
            keepAnswers: keepAnswersFunction
          }
      );
    }

    function requestClearAnswers() {
      displayClearAnswersAlert(function () {
        setMarkingLast(false);
        model.setResponse(null);
        resetTimer();
      }, function(){

      });
    }

    $scope.onTimerBegin = function () {
      if (isEditing() && !isMarkingLast()) {
        model.setTimerIsRunning(true);
        return true;
      }

      return false;
    };

    $scope.onTimerFinish = function () {
      model.setTimerIsRunning(false);
      ModalService.show(
          {
            title: 'Mark Last Word',
            message: 'Time is up. Please mark last word.',
            buttons: [{
                  title: 'OK',
                  click: '$hide();'
                }],
            backdrop: 'static',
            displayCloseButton: false,
            onHide: function() {requestMarkLastWord();}
          }
      );
    };

    function requestMarkLastWord() {
      setMarkingLast(true);
    }

    var _timerResetCallback = null;

    $scope.registerTimerReset = function (callback) {
      _timerResetCallback = callback;
    };

    function resetTimer() {
      if (!angular.isUndefined(_timerResetCallback) && _timerResetCallback !== null)
        _timerResetCallback();
    }

    $scope.showWordsPerMinute = function () {
      return model.getWordsPerMinute() !== null;
    };

    $scope.getWordsPerMinute = function () {
      return model.getWordsPerMinute();
    };

  }]);

  module.controller('clientSpireReadingCtrl', ['$scope', function ($scope) {
    var model = null;

    $scope.$watch('xpModel', function () {
      model = $scope.xpModel;
    }, true);

    $scope.onPhraseClick = function (phrase) {
      model.togglePhrase(phrase);
    };
  }]);
})();
