'use strict';

angular.module('client.services').factory('ClassImportStudents', function ($resource) {
  var url = "/main/v1" + '/classes/:cid';
  return $resource(url, {cid: '@cid'}, {
    import: {url: url + '/import', method: 'POST', params: {cid: '@cid'}},
    sendEmail: {url: url + '/email', method: 'PUT', params: {cid: '@cid'}},
    sendInstructions: {url: url + '/instructions_email', method: 'PUT', params: {cid: '@cid'}}
  });
});
