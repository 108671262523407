'use strict';

angular.module('client.services').factory('loginNotificationService', ['$resource',
  function ($resource) {

  var notificationsResource = $resource('/main/v1' + '/notifications/users/:userid/type/:typeid', {userid: '@userid', typeid: '@typeid'},
      {
        'get': {method: 'GET', isArray: true},
        'remove': {method: 'DELETE'}
      });

  function getUpdateNotifications(userid) {
    return notificationsResource.get({userid: userid, typeid: 'release_notes'}).$promise;
  }

  function removeUpdateNotifications(userid) {
    return notificationsResource.remove({userid: userid, typeid: 'release_notes'}).$promise;
  }

  function getVideoNotifications(userid) {
    return notificationsResource.get({userid: userid, typeid: 'new_videos'}).$promise;
  }

  function removeVideoNotifications(userid) {
    return notificationsResource.remove({userid: userid, typeid: 'new_videos'}).$promise;
  }

  return {
    getUpdateNotifications: getUpdateNotifications,
    removeUpdateNotifications: removeUpdateNotifications,
    getVideoNotifications: getVideoNotifications,
    removeVideoNotifications: removeVideoNotifications
  };
}]);
