'use strict';

angular.module('client.services').factory('studentLearningService', ['$resource',
  function ($resource) {

    var schoolResource = $resource('/main/v1' + '/subscriptions/admin/subscriptions/:subscription_id/reports/:report_id/schools', null,
      {
        'get': {method: 'GET', isArray: true}
      });

    function getSchools(subscriptionId, reportId) {
      return schoolResource.get({subscription_id: subscriptionId, report_id: reportId}).$promise;
    }

    var teacherResource = $resource('/main/v1' + '/subscriptions/admin/subscriptions/:subscription_id/reports/:report_id/classes',
      {subscription_id: '@subscription_id', report_id: '@report_id'},
      {'post': {method: 'POST', isArray: true}}
      );

    function getTeachersAndClasses(subscriptionId, reportId, schools) {
      return teacherResource.post({subscription_id: subscriptionId, report_id: reportId, schools: schools}).$promise;
    }

    var expStandardsResource = $resource('/main/v1' + '/experience_templates/collection/standards',
        {top_collection_uuid: '@top_collection_uuid'},
        {
          'post': {method: 'POST', isArray: true, params: {top_collection_uuids: '@top_collection_uuids'}}
        });

    function getExperiencesByStandards(top_collection_uuids) {
      return expStandardsResource.post({top_collection_uuids: top_collection_uuids}).$promise;
    }

    var expPublishableResource = $resource('/main/v1' + '/experience_templates/collection/publishable',
        {top_collection_uuids: '@top_collection_uuids'},
        {
          'post': {method: 'POST', isArray: true, params: {top_collection_uuids: '@top_collection_uuids'}}
        });

    function getExperiencesByPublishable(top_collection_uuids) {
      return expPublishableResource.post({top_collection_uuids: top_collection_uuids}).$promise;
    }

  return {
    getSchools: getSchools,
    getTeachersAndClasses: getTeachersAndClasses,
    getExperiencesByStandards: getExperiencesByStandards,
    getExperiencesByPublishable: getExperiencesByPublishable
  };
}]);
