'use strict';

angular.module('client.services').factory('HelpVideoService', ['$resource', '$log',
  function ($resource, $log) {

    var helpVideoResource = $resource('/main/v1' + '/users/:id/help_videos', {id: '@id'},
    {
      'get': {method: 'GET', isArray: true}
    });

    function getVideos(id) {
      return helpVideoResource.get({id: id}).$promise;
    }

  return {
    getVideos: getVideos
  };
}]);
