'use strict';

angular.module('client.services').factory('ClassCodes', function ($resource) {
  var url = "/main/v1" + '/classes/:cid';
  return $resource(url, {cid: '@cid'}, {
    lock: {url: url + '/lock', method: 'PUT', params: {cid: '@cid'}},
    unlock: {url: url + '/unlock', method: 'PUT', params: {cid: '@cid'}},
    newCode: {url: url + '/new_code', method: 'PUT', params: {cid: '@cid'}}
  });
});

angular.module('client.services').factory('ClassCodeValidator', function ($resource) {
  var url = "/main/v1" + '/classes/:code';
  return $resource(url, {code: '@code'}, {
    validateCode: {url: url + '/validate_code', method: 'GET', params: {code: '@code'}},
    validateCoteacherCode: {url: url + '/validate_coteacher_code', method: 'GET', params: {code: '@code'}}
  });
});
