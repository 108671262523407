'use strict';

(function () {

  var module = angular.module('client.components');

  controller.$inject = ['$location', 'ActiveMode', 'NgTableParams', 'reportLabels', 'ReportingSchoolYearsService',
                        'noTypeReportsList', '$log', 'ReportNavigation', 'User', 'UserInfoDefaultFactory', 'teksService'];

  function controller($location, ActiveMode, NgTableParams, reportLabels, ReportingSchoolYearsService,
                      noTypeReportsList, $log, ReportNavigation, User, UserInfoDefaultFactory, teksService) {

    var ctrl = this;

    var staticColumns = ['schoolName', 'studentName', 'className', 'teacherName'];

    ctrl.adminReport = {};
    ctrl.currentLevel = false;
    ctrl.hasTypes = true;
    ctrl.LEVELS = teksService.LEVELS;

    ctrl.getLabel = new reportLabels('labels', ctrl);

    ctrl.getStyleClass = function (columnName) {
      if(!staticColumns || !columnName){
        return;
      }
      if(staticColumns.indexOf(columnName)>-1){
        return 'xp-report-medium-cell';
      } else {
        return 'xp-report-small-box';
      }
    };

    ctrl.getTitlePopup = function getTitlePopup(columnName, data, columnTitle) {
      if(!staticColumns || !columnName){
        return;
      }
      if(staticColumns.indexOf(columnName)>-1){
        return columnTitle + ': ' + data;
      } else {
        return columnTitle;
      }
    };

    ctrl.isInDrilldown = false;

    ctrl.includeArchived = false;
    ctrl.allowArchived = false;

    ctrl.filterYears = ReportingSchoolYearsService.get();
    ctrl.year = ctrl.filterYears[0].value;
    ctrl.filterYear = ctrl.filterYears[0];

    ctrl.toggleArchived = function toggleArchived() {
      fetchAndUpdateReport();
    };

    ctrl.toggleYear = function toggleYear(selection) {
      ctrl.year = selection.value;
      ctrl.filterYear = selection;
      ctrl.includeArchived = selection.archived;
      fetchAndUpdateReport();
    };

    ctrl.columns = [];
    ctrl.standardExpectations = [];
    ctrl.expectationCodes = {};
    ctrl.metrics = [
      {name:'Average Quiz Item Score', id:'quiz'},
      {name:'Total Responses', id:'responses'},
      {name:'Number of Breakouts Addressed', id:'number_of_breakouts'},
      {name:'Time on Standard (min.)', id:'time_on_standard', label: 'label-summary-metric-time-on-standard', label_default:'Time on Standard (min.)'},
      ];
    ctrl.filterMetricName = ctrl.metrics[0].name;
    ctrl.filterMetric = ctrl.metrics[0].id;

    ctrl.setMetric = function setMetric(selection){
      ctrl.filterMetricName = selection.name;
      ctrl.filterMetric = selection.id;
      teksService.setColumns(ctrl, ctrl.standardExpectations, selection.id, ctrl.standardExpectationLabels);
    };

    ctrl.classes = [{id:'none',name:'No Value'}];
    ctrl.filterClass = ctrl.classes[0];

    ctrl.types = [{id:'none',name:'No Value'}];
    ctrl.filterType = ctrl.types[0];

    ctrl.concepts = [{id:'none',name:'No Value'}];
    ctrl.filterConcept = ctrl.concepts[0];

    var filter = getFilter();
    if (filter.teacher_id) {
      UserInfoDefaultFactory.get({}, {'id': filter.teacher_id}).$promise
      .then(function (response) {
        ctrl.classTeacherName = response.first_name + " " + response.last_name;
      });
    }
    else {
      ctrl.classTeacherName = User.getCurrentUser().first_name + " " + User.getCurrentUser().last_name;
    }

    ctrl.setClass = function setClass(selection) {
      if(!selection || !selection.id){
        ctrl.filterClass = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.filterClass = selection;
      return teksService.updateReport(ctrl);
    };

    ctrl.setType = function setType(selection) {
      if(!selection || !selection.id){
        ctrl.filterType = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.filterType = selection;
      return teksService.updateReport(ctrl);
    };

    ctrl.setConcept = function setConcept(selection) {
      if(!selection || !selection.id){
        ctrl.filterConcept = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.filterConcept = selection;
      ctrl.types = filterTypes(ctrl.expectationCodes).filter(function filterOutByConcept(rec) {
        return ctrl.expectationCodes[ctrl.filterConcept.id + ':' + rec.id];
      });
      ctrl.filterType = ctrl.types[0];
      return teksService.updateReport(ctrl);
    };

    ctrl.goToStudentSummary = function goToStudentSummary(teacherId, classId) {
      if(!teacherId || !classId){
        return false;
      }
      ReportNavigation.navigateToReport(ctrl.adminReport.id, $location.path(),
        {
          teacher_id: teacherId,
          class_id: classId,
          concept_id: ctrl.filterConcept.id,
          type_id: ctrl.filterType.id,
          metric_id: ctrl.filterMetric,
          studentsummary: 'yes',
          include_archived: ctrl.includeArchived ? 'yes': null,
          year: ctrl.year
        },
        $location.path(),
        {
          concept_id: ctrl.filterConcept.id,
          type_id: ctrl.filterType.id,
          metric_id: ctrl.filterMetric,
          include_archived: ctrl.includeArchived ? 'yes': null,
          year: ctrl.year
        });
    };

    ctrl.classReportDrilldown = function classReportDrilldown(teacherId, classId) {
      if(!teacherId || !classId){
        return false;
      }
      ReportNavigation.navigateToReport(ctrl.adminReport.id, $location.path(),
        {
          teacher_id: teacherId,
          class_id: classId,
          concept_id: ctrl.filterConcept.id,
          type_id: ctrl.filterType.id,
          metric_id: ctrl.filterMetric,
          drilldown: true,
          include_archived: ctrl.includeArchived ? 'yes': null,
          year: ctrl.year
        },
        $location.path(),
        {
          concept_id: ctrl.filterConcept.id,
          type_id: ctrl.filterType.id,
          metric_id: ctrl.filterMetric,
          include_archived: ctrl.includeArchived ? 'yes': null,
          year: ctrl.year
        });
    };

    ctrl.data = [];
    ctrl.reportData = [];
    ctrl.tableParams = new NgTableParams(
      {
        // items per page
        count: 12,
        sorting: { filterDate: "desc" }
      },
      {
        dataset: [],
        // options for page size
        counts: [],
      }
    );

    function filterConcepts(data) {
      var concepts = teksService.generateUniqueTypeConceptFunction('concept')(data);
      var history = concepts.filter(function (concept) {
        return (concept.name||'').toLowerCase() === ('History').toLowerCase();
      })[0];
      if(history){
        return [history].concat(concepts.filter(function (concept) {
          return concept.id !==history.id;
        }))
      }
      return concepts;
    }

    function filterTypes(data) {
      var concepts = teksService.generateUniqueTypeConceptFunction('type')(data);
      var readiness = concepts.filter(function (rec) {
        return (rec.name||'').toLowerCase() === ('Knowledge and skills - Readiness').toLowerCase();
      })[0];
      if(readiness){
        return [readiness].concat(concepts.filter(function (rec) {
          return rec.id !==readiness.id;
        }))
      }
      return concepts;
    }

    function getFilter() {
      var filter = {};
      var query = $location.search();
      filter.class_id = parseInt(query.class_id,10);
      filter.include_archived = query.include_archived || ctrl.includeArchived;
      filter.year = query.year || ctrl.year;
      filter.teacher_id = parseInt(query.teacher_id,10);
      filter.type_id = parseInt(query.type_id, 10);
      filter.concept_id = parseInt(query.concept_id, 10) || ctrl.filterConcept.id;
      filter.drilldown = query.drilldown || false;
      filter.metric_id = query.metric_id;
      filter.studentsummary = query.studentsummary === 'yes';
      filter.show_suggested = query.show_suggested;
      return filter;
    }

    function fetchAndUpdateReport() {
      var filter = getFilter();
      filter.include_archived = ctrl.includeArchived;
      var isInDrilldown = filter.studentsummary;

      ctrl.isInDrilldown = isInDrilldown;

      return ActiveMode.getReportData(filter).then(function (report) {
        ctrl.parent.title = (report.name || '').replace('TEKS', ctrl.getLabel('label-standard-name', 'TEKS'));
        ctrl.adminReport.id = report.report_id;
        ctrl.currentLevel = report.level;
        if(isInDrilldown){
          ctrl.currentLevel = ctrl.LEVELS.CURRICULUM;
        }
        ctrl.labels = report.labels;
        ctrl.hasTypes = !noTypeReportsList.includes(report.report_kind);

        ctrl.metrics.forEach(function (metric) {
          if (metric.label){
            metric.name =ctrl.getLabel(metric.label, metric.label_default);
          }
        });

        ctrl.reportData = report.data.records;
        ctrl.expectationCodes = report.data.short_expectation_codes||{};
        if (report.data.short_expectation_codes) {
          ctrl.concepts = filterConcepts(ctrl.expectationCodes);
          ctrl.types = filterTypes(ctrl.expectationCodes).filter(function filterOutByConcept(rec) {
            return ctrl.expectationCodes[ctrl.filterConcept.id + ':' + rec.id];
          });
        }

        if(filter.concept_id){
          ctrl.filterConcept = ctrl.concepts.filter(function (rec) {
            return rec.id === filter.concept_id;
          })[0] || ctrl.concepts[0];
        } else if (!ctrl.filterConcept) {
          ctrl.filterConcept = ctrl.concepts[0];
        }

        if(!ctrl.filterType && !filter.type_id){
          ctrl.filterType = ctrl.types[0];
        } else if((!ctrl.filterType || ctrl.filterType.id === 'none') && report.data.short_expectation_codes) {
          ctrl.types = filterTypes(ctrl.expectationCodes).filter(function filterOutByConcept(rec) {
            return ctrl.expectationCodes[ctrl.filterConcept.id + ':' + rec.id];
          });
          ctrl.filterType = ctrl.types.filter(function (rec) {
            return rec.id === filter.type_id;
          })[0] || ctrl.types[0];
        }

        if (filter.year) {
          ctrl.filterYear = ctrl.filterYears.filter(function(year) {
            return year.value === parseInt(filter.year, 10);
          })[0] || ctrl.filterYears[0];
        }

        if(filter.metric_id){
          ctrl.filterMetric = ctrl.metrics.filter(function (rec) {
            return rec.id === filter.metric_id;
          }).map(function (rec) {
            return rec.id;
          })[0] || ctrl.metrics[0].id;

          ctrl.filterMetricName = ctrl.metrics.filter(function (rec) {
            return rec.id === ctrl.filterMetric;
          })[0].name;
        }

        ctrl.allowArchived = teksService.isDistrictReport(ctrl) || teksService.isSchoolReport(ctrl);

        if (teksService.isCurriculumReport(ctrl) && report.data.records.length > 0) {
          ctrl.allowArchived = false;
          ctrl.classes = teksService.generateUniqueClassList(report.data.records);
          ctrl.filterClass = ctrl.classes[0];

          if(isInDrilldown){
            ctrl.filterClass = ctrl.classes.filter(function (rec) {
              return rec.id === filter.class_id;
            })[0];
          }

          var firstRecord = report.data.records[0] || {};
          if(firstRecord.teacher_first_name || firstRecord.teacher_last_name){
            ctrl.classTeacherName = firstRecord.teacher_first_name + ' ' + firstRecord.teacher_last_name;
          }
        }

        if(!report.data.short_expectation_codes){
          ctrl.data = [];
          return;
        }

        teksService.updateReport(ctrl);
        return report;
      });
    }

    ctrl.inited = false;

    var preFilter = getFilter();
    ctrl.includeArchived = preFilter.include_archived || false;
    ctrl.showSuggested = preFilter.show_suggested === 'yes';

    fetchAndUpdateReport()
      .then(function () {
        ctrl.inited = true;
      })
      .catch(function(error) {
        $log.error("error in updating report:",error);
        ctrl.inited = true;
      });

  }

  module.component('curriculumTeksSummary', {
    require: {parent: '^^xpReport'},
    template: require('./curriculumTEKSSummary.jade'),
    controller: controller,
  });

})();
