'use strict';

angular.module('client.services').factory('classReports', ['$q', '$resource', '$log',
  function ($q, $resource, $log) {

    var classReports = $resource('/main/v1' + '/subscriptions/admin/classes/:class_id/reports');
    var classReportData = $resource('/main/v1' + '/subscriptions/admin/classes/:class_id/reports/:report_id', null,
      {
        'query': {method: 'GET', isArray: false}
      });

    function getReports(classId) {
      var dfd = $q.defer();

      // If this is not a valid
      if (!classId) {
        dfd.resolve([]);
      }
      else {
        classReports.query({class_id: classId}).$promise.then(function (reportDefs) {
          dfd.resolve(reportDefs);
        })
          .catch(function (error) {
            $log.error("error in getting class reports:", error);
            dfd.reject();
          });
      }
      return dfd.promise;
    }

    function getReportData(classId, reportId, filter) {
      var dfd = $q.defer();

      // If this is not a valid
      if (!classId || !reportId) {
        dfd.resolve([]);
      }
      else {
        classReportData.query({
          class_id: classId,
          report_id: reportId,
          filter: filter
        }).$promise.then(function (reportData) {
          dfd.resolve(reportData);
        })
          .catch(function (error) {
            $log.error("error in getting class report's data:", error);
            dfd.reject();
          });
      }
      return dfd.promise;
    }

    return {
      getReports: getReports,
      getReportData: getReportData
    };
  }]);

