'use strict';

angular.module('client.services').factory('tourService', ['$rootScope', '$resource', '$q', 'User', '$timeout', 'ModalService', 'LicensePlans', 'PageService',
                            function ($rootScope, $resource, $q, User, $timeout, ModalService, LicensePlans, PageService) {

  var currentTourTopic = null;
  var currentTourId = 0;
  var topicTours = [];
  var tourSteps = [];
  var currentTourStep = null;
  var showCancelTour = false;
  var nextStepIncrement = 0;

  var loadingTopics = false;
  function loadTopicsAndTours(topicId) {
    if (!loadingTopics && (!currentTourTopic || currentTourTopic.id != topicId)) {
      loadingTopics = true;
      getTourTopics().then(function(topics) {
        currentTourTopic = topics.find(function(topic) { return topic.id === topicId; });
        if (currentTourTopic) {
          getTopicTours(currentTourTopic.id).then(function(tours) {
            topicTours = tours;
            notify();
            loadingTopics = false;
          });
        }
      });
    }
  }

  function showTours() {
    var userData = User.getCurrentUser();
    if (User.isTeacher() && userData && userData.active_tour_topic_id) {
      loadTopicsAndTours(userData.active_tour_topic_id);
      return true;
    } else {
      return false;
    }
  }

  var isEndOfTour = false;
  function showTaskList() {
    var curPage = PageService.getNavView();
    if (showTours() && curPage === "experiences") {
      if (getTours().length) {
        if (!tourSteps.length || isEndOfTour) {
          if (!ModalService.list().length)
          return true;
        }
      }
    }
    return false;
  }

  var switchingSteps = false;
  function showOnboardingStep() {
    return showTours() && topicTours.length && tourSteps.length && !switchingSteps;
  }

  function subscribe(scope, callback) {
    var handler = $rootScope.$on('activate-tour', callback);
    scope.$on('$destroy', handler);
  }

  function notify() {
    $rootScope.$emit('activate-tour');
  }

  var tourTopicsResource = $resource('/main/v1/' + 'tour_topics', {}, {
        getTourTopics: {method: 'GET', isArray: true}
  });

  function getTourTopics() {
    return tourTopicsResource.getTourTopics({}).$promise;
  }

  function getTourTopic() {
    if (currentTourTopic) {
      return currentTourTopic.name;
    } else {
      return "";
    }
  }

  var toursResource = $resource('/main/v1/' + 'tour_topics/:topicId/tours', {}, {
        getTours: {method: 'GET', isArray: true}
  });

  function getTopicTours(id) {
    if (id) {
      return toursResource.getTours({topicId: id}).$promise;
    } else {
      return $q.when([]);
    }
  }

  var tourStepsResource = $resource('/main/v1/' + 'tour_topics/:topicId/tours/:id/steps', {}, {
        getTourSteps: {method: 'GET', isArray: true}
  });

  function assignStepProperties(step) {
    var tourStep = {
        title: step.title,
        position: step.position,
        description: step.description,
        attachTo: step.attachTo,
        // showButtons: false,
        // delay: 0,
        width: step.width,
        height: step.height,
        top: step.top,
        left: step.left,
        bottom: step.bottom,
        right: step.right,
        xOffset: step.xOffset,
        yOffset: step.yOffset,
        showButtons: step.showButtons,
        arrowCenter: step.arrowCenter,
        arrowHeight: step.arrowHeight,
        arrowXOffset: step.arrow_x_offset,
        arrowYOffset: step.arrow_y_Offset,
        delay: step.delay,
        isEOT: step.end_of_tour,
        stepIncrement: step.step_increment,
        selector: step.selector
    }
    if (step.focusOn) {
      tourStep.focusOn = step.focusOn;
    } else {
      tourStep.focusOn = step.attachTo;
    }
    return tourStep;
  }

  function setTour(tourId) {
    if (tourId != 0 && currentTourTopic && tourId != currentTourId) {
      currentTourId = tourId;
      currentTourStep = null;
      isEndOfTour = false;
      tourStepsResource.getTourSteps({topicId: currentTourTopic.id, id: currentTourId}).$promise
      .then(function(steps) {
        tourSteps = steps.map(function(step) {
          return assignStepProperties(step);
        });
        currentTourStep = 0;
        notify();
      });
    } else {
      currentTourId = tourId;
      currentTourStep = null;
      tourSteps = [];
      notify();
    }
  }

  function getTours() {
    return topicTours;
  }

  var userTourResource = $resource('/main/v1/' + 'tour_topics/:topicId', {topicId: '@topicId'}, {
        setTourTopic: {method: 'PUT'}
  });

  function saveUserCurrentTourTopic(topicId) {
    return userTourResource.setTourTopic({topicId: topicId}).$promise.then(function(res) {
      var userData = User.getCurrentUser();
      userData.active_tour_topic_id = topicId;
      User.setCurrentUser(userData);
      if (topicId === 0) {
        reset();
      }
    });
  }

  var userTourCompletedResource = $resource('/main/v1/' + 'tour_topics/:topicId/tours/:id/status/:status', {topicId: '@topicId', id: '@id', status: '@status'}, {
        setTourTopic: {method: 'PUT'}
  });

  function tourCompleted() {
    return userTourCompletedResource.setTourTopic({topicId: currentTourTopic.id, id: currentTourId, status: 1}).$promise;
  }

  function getTourSteps() {
    return tourSteps;
  }

  function getTourStepIndex() {
    return currentTourStep;
  }

  function gotoNextStepIndex() {
    if (tourSteps && tourSteps.length && currentTourStep < tourSteps.length) {
      $timeout(function() {
        switchingSteps = false;
        var increment = 1;
        if (nextStepIncrement) {
          increment = nextStepIncrement;
          nextStepIncrement = 0;
        }
        if (tourSteps[currentTourStep].stepIncrement) {
          increment = tourSteps[currentTourStep].stepIncrement;
        }
        currentTourStep = currentTourStep + increment;
        notify();
      }, tourSteps[currentTourStep].delay);
    }
  }

  function endingPreviewForEPS() {
    return LicensePlans.getAssignable({'userId': User.getId()}).$promise.then(function (result) {
      var eps = {ww: false, spire: false};
      if (currentTourId == 3 && result && result.length) {
        result.forEach(function (license) {
          if (license.plan_token.toLowerCase() === 'ispire') {
            eps.spire = true;
          } else if (license.plan_token.toLowerCase() === 'spire') {
            eps.star = true;
          } else if (license.plan_token.toLowerCase() === 'wordlywise') {
            eps.ww = true;
          }
        });
      }
      return eps;
    });
  }

  function showEPSTutorials(eps) {
      var config = {
      title: 'EPS Tutorials',
      backdrop: 'static',
      template: require('../onBoarding/epsTutorials.jade'),
      eps: eps,
      spireTutorial: function() {
        window.open('http://ispire.com/tutorials/', '_blank');
      },
      starTutorial: function() {
        window.open('https://epscurriculumsupport.wikipage.io/c/113737762/spire+star/', '_blank');
      },
      wwTutorial: function() {
        window.open('http://wordlywise3000.com/tutorials/', '_blank');
      }
    };

    ModalService.show(config);
  }

  function gotoNextTourStep() {
    if (!isTouring()) {
      return;
    }
    if (currentTourStep == null) {
      currentTourStep = 0;
      notify();
    } else if (currentTourStep == tourSteps.length -1 ) {
      return tourCompleted().then(function() {
        reset();
        var userData = User.getCurrentUser();
        loadTopicsAndTours(userData.active_tour_topic_id);
      });
    } else {
      if (tourSteps[currentTourStep + 1]) {
        isEndOfTour = tourSteps[currentTourStep + 1].isEOT;
      }
      switchingSteps = true;
      if (isEndOfTour) {
        return tourCompleted().then(function() {
          return endingPreviewForEPS().then(function(eps) {
            if (eps.ww || eps.spire) {
              showEPSTutorials(eps);
              ++currentTourId;
              return tourCompleted().then(function() {
                saveUserCurrentTourTopic(0);
              });
            } else {
              gotoNextStepIndex();
              currentTourTopic = null;
              var userData = User.getCurrentUser();
              loadTopicsAndTours(userData.active_tour_topic_id);
            }
          });
        });
      } else {
        gotoNextStepIndex();
      }
    }
  }

  function gotoPreviousTourStep() {
    if (currentTourStep == null) {
      currentTourStep = 0;
    } else {
      currentTourStep = currentTourStep - 1;
    }
    notify();
  }

  function isTouring() {
    return currentTourId > 0;
  }

  function reset() {
    currentTourTopic = null;
    currentTourId = 0;
    topicTours = [];
    tourSteps = [];
    currentTourStep = null;
    loadingTopics = false;
    showCancelTour = false;
    isEndOfTour = false;
  }

  function setNextStepIncrement(increment) {
    $timeout(function() {
      nextStepIncrement = increment;
    }, 1000);
  }

  function isActionStep() {
    if (!isTouring()) {
      return true;
    }
    if (tourSteps && tourSteps.length && currentTourStep < tourSteps.length) {
      if (tourSteps[currentTourStep].showButtons) {
        return false;
      }
    }
    return true;
  }

  function canNavigate(selector) {
    if (!isTouring() || !selector) {
      return true;
    }
    if (tourSteps && tourSteps.length && currentTourStep < tourSteps.length) {
      if (tourSteps[currentTourStep].selector !== selector) {
        return false;
      }
    }
    return true;
  }

  function getCancelTour() {
    return showCancelTour;
  }

  function setCancelTour(cancelTour) {
    showCancelTour = cancelTour;
  }

  return {
    subscribe: subscribe,
    notify: notify,
    showTours: showTours,
    showTaskList: showTaskList,
    showOnboardingStep: showOnboardingStep,
    getTourTopics: getTourTopics,
    getTourTopic: getTourTopic,
    setTour: setTour,
    getTours: getTours,
    saveUserCurrentTourTopic: saveUserCurrentTourTopic,
    getTourSteps: getTourSteps,
    getTourStepIndex: getTourStepIndex,
    gotoNextTourStep: gotoNextTourStep,
    gotoPreviousTourStep: gotoPreviousTourStep,
    isTouring: isTouring,
    getCancelTour: getCancelTour,
    setCancelTour: setCancelTour,
    reset: reset,
    setNextStepIncrement: setNextStepIncrement,
    isActionStep: isActionStep,
    canNavigate: canNavigate
  };

}]);
