'use strict';
(function(){
var module = angular.module('xp-element-spire-placement', ['client.services', 'ngAnimate', 'ngSanitize', 'mgcrea.ngStrap', 'client.directives', 'xp-element-spire-common']);


module.factory('xpSpirePlacement', ['$resource', function($resource) {

  var spirePlacementService = $resource('/main/v1' + '/data/spire/placement_words', {}, {
    'get': {method: 'GET', isArray: false}
  });

  function parseData(data) {
    var calculated = { placements: data.placements, headers: {}, words: {} };

    data.words.forEach(function(word) {
      calculated.headers[word.placement_id] = calculated.headers[word.placement_id] || {};
      calculated.headers[word.placement_id][word.section] = calculated.headers[word.placement_id][word.section] || {};
      calculated.headers[word.placement_id][word.section][word.display_column] = word.header;

      calculated.words[word.placement_id] = calculated.words[word.placement_id] || {};
      calculated.words[word.placement_id][word.section] = calculated.words[word.placement_id][word.section] || {};
      calculated.words[word.placement_id][word.section][word.sort_order] = calculated.words[word.placement_id][word.section][word.sort_order] || {};
      calculated.words[word.placement_id][word.section][word.sort_order][word.display_column] = {id: word.id, word: word.word};
    });

    return calculated;
  }

  function getPlacement() {
    return spirePlacementService.get({}, {}).$promise.then(function(value){
      return parseData(value);
    });
  }

  return getPlacement;
}]);

module.factory('xpSpirePlacementScoresService', ['$resource', function($resource){
  var url = "/main/v1/" + "data/spire/placement_words/:experienceId/scores";
  return $resource(url, { experienceId: '@experienceId'},
      {
        get: {method: 'GET', isArray: true, params: {experienceId: '@experienceId'}}
      });
}]);

module.controller('clientSpirePlacementElementCtrl', ['$scope', '$log', 'ElementsRestService', 'ElementsErrorService', 'spire', 'xpSpirePlacement', 'xpSpirePlacementScoresService',
                                                                         function ($scope, $log, ElementsRestService, ElementsErrorService, spire, xpSpirePlacement, xpSpirePlacementScoresService) {
  spire.configureScope($scope, parseElement, displayUserResponses, isResponseModified, "placement test");

  $scope.scores = {};
  $scope.incorrectWords = [];

  function compareByFirstName(student1, student2) {
    if (student1.firstName.toLowerCase() < student2.firstName.toLowerCase())
      return -1;
    if (student1.firstName.toLowerCase() > student2.firstName.toLowerCase())
      return 1;
    return 0;
  }

  function compareByName(student1, student2) {
    if (student1.lastName.toLowerCase() < student2.lastName.toLowerCase())
      return -1;
    if (student1.lastName.toLowerCase() > student2.lastName.toLowerCase())
      return 1;
    return compareByFirstName(student1, student2);
  }

  $scope.isTeacher = $scope.options.context.userIsTeacher();
  if ($scope.isTeacher) {
    $scope.students = $scope.options.context.clazz.students;
  } else {
    $scope.students = $scope.options.context.clazz.students.filter(function(student) {
      return student.uid === $scope.options.context.userId;
    });
  }

  $scope.students.sort(compareByName);

  $scope.testTakingMode = false;
  xpSpirePlacement().then(function(data){
    $scope.placement = data;
  });

  if ($scope.isTeacher) {
    getPlacementScores();
  }

  function parseElement() {
  }

  function displayUserResponses(user) {
  }

  function isResponseModified() {
    return false;
  }

  function getPlacementScores() {
    return xpSpirePlacementScoresService.get({experienceId: $scope.options.context.experienceId}).$promise.then(function(scores) {
      scores.forEach(function(score) {
        $scope.scores[score.student_id] = $scope.scores[score.student_id] || {};
        $scope.scores[score.student_id][score.placement_id] = score.incorrect_word_count;
      });
    });
  }

  $scope.onTakePlacement = function(studentId, level) {
    if (!$scope.isTeacher) {
      return;
    }
    $scope.studentId = studentId;
    $scope.selectedStudent = studentId;
    $scope.level = level;
    $scope.incorrectWords = [];
    $scope.comment.text = "";

    ElementsRestService.getUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.selectedStudent, function (result, error) {
      if (result && result.user_data) {
        try {
          var existingStudentResponses = JSON.parse(result.user_data);
          if (existingStudentResponses && existingStudentResponses.length) {
            var studentLevelResponse = existingStudentResponses.find(function(response) { return response.level === $scope.level.id; });
            if (studentLevelResponse) {
              $scope.incorrectWords = studentLevelResponse.incorrectWords;
              $scope.comment.text = studentLevelResponse.comment;
            }
          }
        }
        catch (e) {
          $log.error("error in parsing user state for teacher gate:",state);
          return {};
        }
      }
    });


    $scope.testTakingMode = true;
  };

  $scope.getRowWords = function(section) {
    return $scope.placement.words[$scope.level.id][section + 1];
  };

  $scope.getWordStyle = function(wordId) {
    if ($scope.incorrectWords.includes(wordId)) {
      return "placement-word-incorrect";
    }
  };

  $scope.incorrectWord = function(wordId) {
    if ($scope.incorrectWords.includes(wordId)) {
      $scope.incorrectWords = $scope.incorrectWords.filter(function(word) { return word !== wordId; });
    } else {
      $scope.incorrectWords.push(wordId);
    }
  };

  $scope.getScore = function(studentId, levelId) {
    if ($scope.scores[studentId] && $scope.scores[studentId][levelId] >= 0) {
      return $scope.scores[studentId][levelId];
    }
  };

  $scope.getScoreStyle = function(studentId, levelId) {
    if (levelId === 1 && $scope.scores[studentId] && $scope.scores[studentId][levelId] >= 1) {
      return "client-spire-incorrect-phrase"
    }
    if ($scope.scores[studentId] && $scope.scores[studentId][levelId] >= 0) {
      if ($scope.scores[studentId][levelId] <= 7) {
        return "client-spire-placement-correct";
      } else {
        return "client-spire-incorrect-phrase";
      }
    }
    return "";
  };

  $scope.canSubmit = function() {
    return true;
  };

  $scope.didSubmit = function () {
    if ($scope.selectedStudent === null || !$scope.isTeacher)
      return;

    var response = {};
    response.level = $scope.level.id;
    response.incorrectWords = $scope.incorrectWords;
    response.comment = $scope.comment.text;

    ElementsRestService.getUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.selectedStudent, function (result, error) {
      var studentResponses = [];
      if (result && result.user_data) {
        try {
          var existingStudentResponses = JSON.parse(result.user_data);
          if (existingStudentResponses && existingStudentResponses.length) {
            studentResponses = existingStudentResponses.map(function(existingResp) {
              if (existingResp.level === $scope.level.id) {
                return response;
              } else {
                return existingResp;
              }
            });
            var studentLevelResponse = studentResponses.find(function(resp) { return resp.level === $scope.level.id; });
            if (!studentLevelResponse) {
              studentResponses.push(response);
            }
          } else {
            studentResponses.push(response);
          }
        }
        catch (e) {
          $log.error("error in parsing user state for teacher gate:",state);
          return;
        }
      } else {
        studentResponses.push(response);
      }
      ElementsRestService.saveUserState($scope.options.context.experienceId, $scope.options.element.id, $scope.selectedStudent, -1, JSON.stringify(studentResponses),
          function () {
        $scope.testTakingMode = false;
        getPlacementScores();
      },
      function (error) {
        ElementsErrorService.error(error);
      });
    });
  };

  $scope.didCancel = function() {
    $scope.testTakingMode = false;
  };

}]);
})();

