'use strict';

angular.module('client.services').factory('SubscriptionSettings', function ($resource) {
    var url = "/main/v1/" + "subscriptions/users/:userId/settings";
    var settingsService = $resource(url, {userId: '@userId'},
    {
      get: {method: 'GET', isArray: true, params: {userId: '@userId'}}
    });

    function getSubscriptionSettings(userId) {
      return settingsService.get({userId: userId}).$promise
      .then(function(settings) {
        return settings;
      });
    }

    function isProctor(settings) {
      let proctorKey = settings.find(function(setting) {
        return setting.key == 'is_proctor';
      });
      if (proctorKey && proctorKey.value.toLowerCase() == 'yes') {
        return true;
      }
      return false;
    }

    return {
      getSubscriptionSettings: getSubscriptionSettings,
      isProctor: isProctor
    };
});
