'use strict';

angular.module('client.services').factory('LicensePlans', function ($resource) {
  var url = "/main/v1" + '/subscriptions/users/:userId';
  return $resource(url, {userId: '@userId'}, {
    get: {url: url, method: 'GET', params: {userId: '@userId'}, isArray: true, cache: true},
    getExposed: {url: url + '/exposed', method: 'GET', params: {userId: '@userId'}, isArray: true, cache: false},
    getAssignable: {url: url + '/assignable', method: 'GET', params: {userId: '@userId'}, isArray: true, cache: true}
  });
});
