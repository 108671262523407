'use strict';

/**
 * @file Experience List Layout Controller
 * @author Joshua Jack <joshua.jack@exploros.com>
 * @description Determines which experience list controller to load based on user role
 * @version 1.0
 */

// TODO: Create a proper middleware for front-end routes based on role

clientControllers.controller('ExperienceListLayoutCtrl', [
  '$scope', '$rootScope', '$controller', '$location', '$timeout', '$log', 'User', 'UserInfoDefaultFactory', 'ActiveMode',
  function ($scope, $rootScope, $controller, $location, $timeout, $log, User, UserInfoDefaultFactory, ActiveMode) {
    $controller('ExperienceListCtrl', {
      $scope: $scope
    });

    $scope.displayExperienceSummaryInfo = ActiveMode.isSubscriptionMode() || ActiveMode.isDistrictAdminMode();
    $scope.displayExperienceFilters = ActiveMode.isDistrictAdminMode();

    function loadAdminDashboard() {
      $scope.refreshingData = false;

      $scope.subscriptionId = ActiveMode.currentSubscriptionId();

      $scope.updateSummaryRefreshTime = function () {
        $scope.summaryRefreshTime = new Date();
      };

      $scope.summaryRefresh = function () {
        $scope.refreshingData = true;

        $timeout(function () {
          $scope.refreshingData = false;
        }, 1000);

        $scope.loadExperiences()
          .then($scope.getPastExperiences)
          .then($scope.updateSummary)
          .catch(function (err) {
            $log.error('error refreshing summary data:', err);
          });
      };

      $scope.experienceSummary = {teachers: 0, experiences: 0, students: 0};

      ($scope.updateSummary = function () {
        $scope.updateSummaryRefreshTime();
      }).call();
    }

    $scope.dateFormatter = function (systemDate) {
      return $rootScope.dateTimeFormatter(systemDate, true);
    };

    $scope.$watch('experiences.active', function () {
      if ($scope.experiences.active) {
        // Reset summary data on re-calculate
        $scope.experienceSummary = {teachers: 0, experiences: 0, students: 0};

        // Number of active experiences is just the # of returned experiences
        $scope.experienceSummary.experiences = $scope.experiences.active.length;

        // Iterate the active experiences looking for unique teachers and students
        var uniqueTeachers = [];
        var uniqueStudents = [];
        $scope.experiences.active.forEach(function (experience) {
          // Look to see if we have already recorded this teacher, otherwise add them to the list
          if (uniqueTeachers.indexOf(experience.teacher.id) == -1)
            uniqueTeachers.push(experience.teacher.id);

          // if there is a list of invited students
          if (experience.invited_student_ids) {
            // Iterate looking for unique students
            experience.invited_student_ids.forEach(function (studentId) {
              // check to see if this user is already in the list
              if (uniqueStudents.indexOf(studentId) == -1)
                uniqueStudents.push(studentId);
            });
          }
        });

        // Assign to size of unique array
        $scope.experienceSummary.teachers = uniqueTeachers.length;
        $scope.experienceSummary.students = uniqueStudents.length;
      }
    });

    if ($scope.displayExperienceSummaryInfo)
      loadAdminDashboard();
  }]);
