'use strict';

angular.module('client.services').factory('rosterService', ['$resource', '$q', 'ExperienceGroupListFactory', 'CourseFactory', '$log', 'User',
  function ($resource, $q, ExperienceGroupListFactory, CourseFactory, $log, User) {

  var cachedClassRosterId = null;
  var classRoster = null;
  function getClassRoster(classId, refresh) {
    if (!classRoster || refresh || cachedClassRosterId != classId) {
      return CourseFactory.getStudents(classId).then(function(students) {
        cachedClassRosterId = classId;
        classRoster = students;
        return classRoster;
      });
    } else {
      return $q.when(classRoster);
    }
  }

  var cachedRosterTClassId = null
  var classRosterWithTeachers = null;
  function getClassRosterWithTeachers(classId, refresh) {
    if (!classRosterWithTeachers || refresh || cachedRosterTClassId != classId) {
      return CourseFactory.getStudentsAndTeachers(classId).then(function(students) {
        cachedRosterTClassId = classId;
        classRosterWithTeachers = students;
        return classRosterWithTeachers;
      });
    } else {
      return $q.when(classRosterWithTeachers);
    }
  }

  var otherTeacherId = null;
  var studentsForTeacher = null;
  function getAllStudents(refresh) {
    if (!studentsForTeacher || refresh || otherTeacherId != User.getId()) {
      return ExperienceGroupListFactory.get({'id': User.getId(), 'group_name': 'All Students'}).$promise.then(function (all) {
        otherTeacherId = User.getId();
        studentsForTeacher = all.students;
        if (refresh) {
          classRosterWithTeachers = null;
        }
        return studentsForTeacher;
      });
    } else {
      return $q.when(studentsForTeacher);
    }
  }

  var campusClassId = null;
  var campusRoster = null;
  function getCampusStudents(classId, refresh) {
    if (!campusRoster || refresh || campusClassId != classId) {
      return CourseFactory.getCampusStudents(classId, refresh).then(function (students) {
        campusClassId = classId;
        campusRoster = students;
        return campusRoster;
      });
    } else {
      return $q.when(campusRoster);
    }
  }

  function getAllRosters(classId, refresh) {
    return $q.all([getClassRoster(classId, refresh), getClassRosterWithTeachers(classId, refresh),
                  getAllStudents(refresh), getCampusStudents(classId, refresh)]).then(function(results){
      var rosters = {
          classRoster: results[0],
          classRosterWithTeachers: results[1],
          teacherRoster: results[2],
          campusRoster: results[3]
      };

      rosters.classRoster = results[0].sort(function(a,b) {
        return a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase());
      });

      rosters.classRosterWithTeachers = results[1].sort(function(a,b) {
        if (a.role.toLowerCase() === 'teacher' && b.role.toLowerCase() === 'student') {
          return -1;
        } else if (a.role.toLowerCase() === 'student' && b.role.toLowerCase() === 'teacher') {
          return 1;
        } else {
          return a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase());
        }
      });

      var teacherNotInClass = results[2].filter(function(student) {
        return !rosters.classRoster.find(function(classStudent) { return classStudent.id === student.id; });
      });

      rosters.teacherRoster = teacherNotInClass.sort(function(a,b) {
        return a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase());
      });

      rosters.campusRoster = results[3].filter(function(student) {
        return !rosters.classRoster.find(function(classStudent) { return classStudent.id === student.id; });
      });

      rosters.campusRoster.forEach(function(student) {
        var yourStudent = rosters.teacherRoster.find(function(teacherStudent) { return teacherStudent.id === student.id; });
        if (yourStudent) {
          student.enrollment = "Your student";
        } else {
          student.enrollment = "Campus student";
        }
      });

      rosters.campusRoster.sort(function(a,b) {
        return b.enrollment.toLowerCase().localeCompare(a.enrollment.toLowerCase());
      });

      return rosters;
    });
  }

  var districtResource = $resource('/main/v1' + '/ims/:id/user/:user_id/district/:district_id', {id: '@id', user_id: '@user_id', district_id: '@district_id'},
  {
    'put': {method: 'PUT'}
  });

  function saveDistrictId(lmsId, studentId, districtId) {
    return districtResource.put({id: lmsId, user_id: studentId, district_id: districtId}).$promise;
  }

  var checkDistrictResource = $resource('/main/v1' + '/ims/:id/district/:district_id', {id: '@id', district_id: '@district_id'},
  {
    'get': {method: 'GET'}
  });

  function checkDistrictId(lmsId, districtId) {
    return checkDistrictResource.get({id: lmsId, district_id: districtId}).$promise;
  }

  var deleteDistrictResource = $resource('/main/v1' + '/ims/:id/user/:user_id/district', {id: '@id', user_id: '@user_id'},
  {
    'delete': {method: 'DELETE'}
  });

  function deleteDistrictId(lmsId, studentId) {
    return deleteDistrictResource.delete({id: lmsId, user_id: studentId}).$promise;
  }

  var learningStatusResource = $resource('/main/v1' + '/ims/:id/learning_statuses', {id: '@lmsId'},
  {
    'get': {method: 'GET', isArray: true}
  });

  function getLearningStatus(lmsId) {
    return learningStatusResource.get({id: lmsId}).$promise;
  }

  var setLearningStatusResource = $resource('/main/v1' + '/ims/:id/user/:user_id/status/:status_id', {id: '@id', user_id: '@user_id', status_id: '@status_id'},
  {
    'put': {method: 'PUT'}
  });

  var deleteLearningStatusResource = $resource('/main/v1' + '/ims/:id/user/:user_id/status', {id: '@id', user_id: '@user_id'},
  {
    'delete': {method: 'DELETE'}
  });

  function setLearningStatus(lmsId, studentId, statusId) {
    if (statusId !== 0) {
      return setLearningStatusResource.put({id: lmsId, user_id: studentId, status_id: statusId}).$promise;
    } else {
      return deleteLearningStatusResource.delete({id: lmsId, user_id: studentId}).$promise;
    }
  }

  return {
    getClassRoster: getClassRoster,
    getClassRosterWithTeachers: getClassRosterWithTeachers,
    getAllStudents: getAllStudents,
    getCampusStudents: getCampusStudents,
    getAllRosters: getAllRosters,
    saveDistrictId: saveDistrictId,
    checkDistrictId: checkDistrictId,
    deleteDistrictId: deleteDistrictId,
    getLearningStatus: getLearningStatus,
    setLearningStatus: setLearningStatus
  };
}]);
