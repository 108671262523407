'use strict';

(function () {
  var module = angular.module('client.permissions', []);
  module.constant('PermissionConsts', {
    // User based permissions
    class_own_create: 'class_own_create',
    class_any_create: 'class_any_create',
    add_experience: 'add_experience',
    add_multi_class_experience: 'add_multi_class_experience',
    student_facing_feedback: 'student_facing_feedback',
    ui_curriculum_hide_student_facing_feedback: 'ui_curriculum_hide_student_facing_feedback',
    ui_navbar_hide_curriculum_reports_button: 'ui_navbar_hide_curriculum_reports_button',
    ui_class_show_licensing_assignment: 'ui_class_show_licensing_assignment',
    open_all_gates_on_activation: 'open_all_gates_on_activation',
    ui_show_admin_interface: 'ui_show_admin_interface',
    ui_show_toggle_teacher_notes: 'ui_show_toggle_teacher_notes',
    ui_disable_rostering: 'ui_disable_rostering',
    ui_analytics_review: 'ui_analytics_review',
    ui_tia_proctor: 'ui_tia_proctor',

    // Class based permissions
    view_class_reports: 'view_class_reports',
    ui_view_class_pack_ispire: 'ui_view_class_pack_ispire',
    ui_view_class_pack_ww: 'ui_view_class_pack_ww',
    ui_view_class_pack_spire: 'ui_view_class_pack_spire',
    ui_campus_rostering: 'ui_campus_rostering',
    ui_assign_student_status: 'ui_assign_student_status',
    ui_assign_district_id: 'ui_assign_district_id',
    ui_license_pack: 'ui_license_pack',
    ui_disable_class_rostering: 'ui_disable_class_rostering',

    // curriculum based permissions
    ui_curriculum_hide_reports_placeholder: 'ui_curriculum_hide_reports_placeholder',
    view_curriculum_reports: 'view_curriculum_reports',
    ui_curriculum_show_dashboard_responses: 'ui_curriculum_show_dashboard_responses',
    integration_curriculum_google_drive: 'integration_curriculum_google_drive',
    ui_curriculum_reactivate_experiences: 'ui_curriculum_reactivate_experiences',
    ui_streaming_video: 'ui_streaming_video',

    // Subscription based permissions
    subscription_admin: 'subscription_admin',
    ispire_rosters_and_licenses: 'ispire_rosters_and_licenses',
    view_district_schools_subscriptions: 'view_district_schools_subscriptions',
    edit_district_schools_subscriptions: 'edit_district_schools_subscriptions',
    import_students_to_classes: 'import_students_to_classes',
    ui_curriculum_show_experience_card_standards: 'ui_curriculum_show_experience_card_standards',

    // school based permissions
    school_admin: 'school_admin',

    // district based permissions
    district_admin: 'district_admin'
  });

  module.factory('userPermissions', ['$q', '$resource', '$rootScope', '$sessionStorage', 'User',
    function ($q, $resource, $rootScope, $sessionStorage, User) {
      var url = "/main/v1/" + "permissions/users/:userId";
      var permissionsService = $resource(url, {userId: '@userId'},
        {
          get: {method: 'GET', isArray: true, params: {userId: '@userId'}}
        });

      var permissions = $sessionStorage['permissions_' + User.getId()] || [];

      function hasPermission(permission) {
        return (permissions || []).indexOf(permission) !== -1;
      }

      function setPermissions(permissionsIn) {
        permissions = permissionsIn.concat();
        $sessionStorage['permissions_' + User.getId()] = permissions;
        return $q.when(permissions);
      }

      function clearPermissions() {
        permissions = [];
        delete $sessionStorage['permissions_' + User.getId()];
        return $q.when([]);
      }

      function resetPermissions() {
        var userId = User.getId();
        if (!userId) {
          return clearPermissions();
        }
        return permissionsService.get({userId: userId}).$promise
          .then(function (data) {
            return setPermissions(data);
          });
      }

      return {
        hasPermission: hasPermission,
        clearPermissions: clearPermissions,
        resetPermissions: resetPermissions
      };
    }]);

  module.factory('userExperiencePermissions', ['$q', '$resource', '$rootScope', '$sessionStorage', 'User',
    function ($q, $resource, $rootScope, $sessionStorage, User) {
      var url = "/main/v1/" + "permissions/experiences/:experienceId";
      var permissionsService = $resource(url, {experienceId: '@experienceId'},
        {
          get: {method: 'GET', isArray: true, params: {experienceId: '@experienceId'}}
        });

      var permissions = {};

      function hasPermission(permission, experienceId) {
        return (permissions[experienceId] || []).indexOf(permission) > -1;
      }

      function setPermissions(experienceId) {
        return permissionsService.get({experienceId: experienceId}).$promise
          .then(function (expPermissions) {
            permissions[experienceId] = expPermissions;
            return expPermissions;
          });
      }

      function clearPermissions() {
        permissions = {};
        return $q.when({});
      }

      function resetPermissions() {
        permissions = {};
      }

      return {
        hasPermission: hasPermission,
        setPermissions: setPermissions,
        clearPermissions: clearPermissions,
        resetPermissions: resetPermissions
      };
    }]);

  module.factory('userClassPermissions', ['$resource', '$rootScope', function ($resource, $rootScope) {
    var url = "/main/v1/" + "permissions/users/:userId/classes/:classId";
    var permissionsService = $resource(url, {userId: '@userId', classId: '@classId'},
      {
        get: {method: 'GET', isArray: true, params: {userId: '@userId', classId: '@classId'}}
      });

    var permissions = [];
    var currentUserId;
    var currentClassId;

    function hasPermission(permission) {
      return (permissions || []).indexOf(permission) != -1;
    }

    function setPermissions(permissionsIn) {
      permissions = permissionsIn.concat();
    }

    function clearPermissions() {
      currentUserId = undefined;
      currentClassId = undefined;
      permissions = [];
    }

    function setUserClassPermissions(userId, classId) {
      userId = parseInt(userId, 10);
      classId = parseInt(classId, 10);

      if (isNaN(userId) || isNaN(classId)) {
        clearPermissions();
        return;
      }

      if (!userId || !classId) {
        clearPermissions();
      }

      if (userId !== currentUserId || classId !== currentClassId) {
        currentUserId = userId;
        currentClassId = classId;
        permissionsService.get({userId: userId, classId: classId}).$promise
          .then(function (data) {
            setPermissions(data);
          });
      }
    }

    function resetPermissions(userId, classId) {
      clearPermissions();
      setUserClassPermissions(userId, classId);
    }

    function hasClassPermission(userId, classId, permission) {
      return permissionsService.get({userId: userId, classId: classId}).$promise
        .then(function (permissions) {
          return (permissions || []).indexOf(permission) != -1;
        });
    }

    return {
      hasPermission: hasPermission,
      setUserClassPermissions: setUserClassPermissions,
      clearPermissions: clearPermissions,
      resetPermissions: resetPermissions,
      hasClassPermission: hasClassPermission
    };
  }]);

  module.factory('userCurriculumPermissions', ['$rootScope', 'PermissionConsts', 'User', function ($rootScope, PermissionConsts, User) {
    var permissions = [];

    function hasPermission(permission) {
      return (permissions || []).indexOf(permission) != -1;
    }

    function setPermissions(permissionsIn) {
      permissions = permissionsIn.concat();
    }

    function clearPermissions() {
      permissions = [];
    }

    function setUserCurriculumPermissions(userId) {
      if (!userId) {
        clearPermissions();
        return;
      }

      // TODO: All permissions are enabled by default so no need to query server.  We should really implement role based security and get this from the backend
      if (User.isTeacher()) {
        setPermissions([PermissionConsts.view_curriculum_reports]);
      }
    }

    function resetPermissions(userId) {
      clearPermissions();
      setUserCurriculumPermissions(userId);
    }

    return {
      hasPermission: hasPermission,
      setUserCurriculumPermissions: setUserCurriculumPermissions,
      clearPermissions: clearPermissions,
      resetPermissions: resetPermissions
    };
  }]);

  module.factory('userSubscriptionPermissions', ['$resource', '$rootScope', function ($resource, $rootScope) {
    var url = "/main/v1/" + "permissions/users/:userId/subscriptions/:subscriptionId";
    var permissionsService = $resource(url, {userId: '@userId', subscriptionId: '@subscriptionId'},
      {
        get: {method: 'GET', isArray: true, params: {userId: '@userId', subscriptionId: '@subscriptionId'}}
      });

    var permissions = [];
    var currentUserId;
    var currentSubscriptionId;

    function hasPermission(permission) {
      return (permissions || []).indexOf(permission) != -1;
    }

    function setPermissions(permissionsIn) {
      permissions = permissionsIn.concat();
    }

    function clearPermissions() {
      currentUserId = undefined;
      currentSubscriptionId = undefined;
      permissions = [];
    }

    function setUserSubscriptionPermissions(userId, subscriptionId) {
      userId = parseInt(userId, 10);
      subscriptionId = parseInt(subscriptionId, 10);

      if (isNaN(userId) || isNaN(subscriptionId)) {
        clearPermissions();
        return;
      }

      if (!userId || !subscriptionId) {
        clearPermissions();
      }

      if (userId !== currentUserId || subscriptionId !== currentSubscriptionId) {
        currentUserId = userId;
        currentSubscriptionId = subscriptionId;
        permissionsService.get({userId: userId, subscriptionId: subscriptionId}).$promise
          .then(function (data) {
            setPermissions(data);
          });
      }
    }

    function resetPermissions(userId, subscriptionId) {
      clearPermissions();
      setUserSubscriptionPermissions(userId, subscriptionId);
    }

    return {
      hasPermission: hasPermission,
      setUserSubscriptionPermissions: setUserSubscriptionPermissions,
      clearPermissions: clearPermissions,
      resetPermissions: resetPermissions
    };
  }]);

  module.factory('userDistrictAdminPermissions', ['$q', '$rootScope', '$resource', 'PermissionConsts', 'User', function ($q, $rootScope, $resource, PermissionConsts, User) {
    var districtsUrl = "/main/v1/" + "permissions/users/:userId/districts/admin";
    var districtsService = $resource(districtsUrl, {userId: '@userId'},
      {
        get: {method: 'GET', isArray: true, params: {userId: '@userId'}}
      });

    var url = "/main/v1/" + "permissions/users/:userId/district/:districtId";
    var permissionsService = $resource(url, {userId: '@userId', districtId: '@districtId'},
      {
        get: {method: 'GET', isArray: true, params: {userId: '@userId', districtId: '@districtId'}}
      });

    var districts = null;
    var permissions = [];
    var currentUserId = null;
    var currentDistrictId = null;

    function getDistricts(userId) {
      if (!districts || currentUserId !== userId) {
        return districtsService.get({userId: userId}).$promise
          .then(function (data) {
            currentUserId = userId;
            districts = data;
            return districts;
          });
      } else {
        return $q.when(districts);
      }
    }

    function getDistrict(districtId) {
      return getDistricts(User.getId()).then(function (districts) {
        var districtById = districts.find(function (district) {
          return district.district_id == districtId;
        });
        return districtById;
      });
    }

    function hasPermission(permission) {
      return (permissions || []).indexOf(permission) != -1;
    }

    function setPermissions(permissionsIn) {
      permissions = permissionsIn.concat();
    }

    function clearPermissions() {
      currentUserId = null;
      currentDistrictId = null;
      districts = null;
      permissions = [];
    }

    function setUserDistrictAdminPermissions(userId, districtId) {
      userId = parseInt(userId, 10);
      districtId = parseInt(districtId, 10);

      if (!userId || !districtId) {
        clearPermissions();
      } else {
        currentUserId = userId;
        currentDistrictId = districtId;
        permissionsService.get({userId: userId, districtId: districtId}).$promise
          .then(function (data) {
            setPermissions(data);
          });
      }
    }

    function resetPermissions(userId, districtId) {
      clearPermissions();
      setUserDistrictAdminPermissions(userId, districtId);
    }

    return {
      getDistricts: getDistricts,
      getDistrict: getDistrict,
      hasPermission: hasPermission,
      setUserDistrictAdminPermissions: setUserDistrictAdminPermissions,
      clearPermissions: clearPermissions,
      resetPermissions: resetPermissions
    };
  }]);

  module.factory('userSchoolAdminPermissions', ['$q', '$rootScope', '$resource', 'PermissionConsts', 'User', function ($q, $rootScope, $resource, PermissionConsts, User) {
    var schoolsUrl = "/main/v1/" + "permissions/users/:userId/schools/admin";
    var schoolsService = $resource(schoolsUrl, {userId: '@userId'},
      {
        get: {method: 'GET', isArray: true, params: {userId: '@userId'}}
      });

    var url = "/main/v1/" + "permissions/users/:userId/school/:schoolId";
    var permissionsService = $resource(url, {userId: '@userId', schoolId: '@schoolId'},
      {
        get: {method: 'GET', isArray: true, params: {userId: '@userId', schoolId: '@schoolId'}}
      });

    var schools = null;
    var permissions = [];
    var currentUserId = null;
    var currentSchoolId = null;

    function getSchools(userId) {
      if (!schools || currentUserId !== userId) {
        return schoolsService.get({userId: userId}).$promise
          .then(function (data) {
            currentUserId = userId;
            schools = data;
            return schools;
          });
      } else {
        return $q.when(schools);
      }
    }

    function getSchool(schoolId) {
      return getSchools(User.getId()).then(function (schools) {
        var schoolById = schools.find(function (school) {
          return school.school_id == schoolId;
        });
        return schoolById;
      });
    }

    function hasPermission(permission) {
      return (permissions || []).indexOf(permission) != -1;
    }

    function setPermissions(permissionsIn) {
      permissions = permissionsIn.concat();
    }

    function clearPermissions() {
      currentUserId = null;
      currentSchoolId = null;
      schools = null;
      permissions = [];
    }

    function setUserSchoolAdminPermissions(userId, schoolId) {
      userId = parseInt(userId, 10);
      schoolId = parseInt(schoolId, 10);

      if (!userId || !schoolId) {
        clearPermissions();
      } else {
        currentUserId = userId;
        currentSchoolId = schoolId;
        permissionsService.get({userId: userId, schoolId: schoolId}).$promise
          .then(function (data) {
            setPermissions(data);
          });
      }
    }

    function resetPermissions(userId, schoolId) {
      clearPermissions();
      setUserSchoolAdminPermissions(userId, schoolId);
    }

    return {
      getSchools: getSchools,
      getSchool: getSchool,
      hasPermission: hasPermission,
      setUserSchoolAdminPermissions: setUserSchoolAdminPermissions,
      clearPermissions: clearPermissions,
      resetPermissions: resetPermissions
    };
  }]);

})();
