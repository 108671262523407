'use strict';

(function(){
  var module = angular.module('client.components');

  controller.$inject = ['ActiveMode', 'reportToPDF', 'ReportingSchoolYearsService', 'reportHelperService', '$log', 'xpAlert'];

function controller (ActiveMode, reportToPDF, ReportingSchoolYearsService, reportHelperService, $log, xpAlert) {
  var ctrl = this;

  ctrl.inited = false;
  ctrl.responsesOverTimeTitle = "Student Responses Over Time";
  ctrl.quizScoresOverTimeTitle = "Quiz Scores Over Time";
  ctrl.gradesTaughtTitle = "Grades Taught";
  ctrl.unsubscribedResponsesTitle = "Responses"
  ctrl.defaultYear = ReportingSchoolYearsService.get()[0];
  ctrl.subscribed = true;
  ctrl.lastUpdated = window.moment().subtract(1, 'day').format('MM/DD/YYYY');
  ctrl.activitySort = "experiences";
  ctrl.experienceSort = "taught";
  ctrl.unsubscribedLegend = "bottom";

  ctrl.selectedSchools = [];
  ctrl.selectedTeachers = [];
  ctrl.selectedCourses = [];
  ctrl.selectedYear = ctrl.defaultYear;

  ctrl.$onInit = function () {
    ActiveMode.getReport(ActiveMode.currentReportId()).then(function(currentReport) {
      ctrl.currentReport = currentReport.report_kind;
      InitReportData({year: ctrl.selectedYear.value});
    })
    .catch(function(error){
      $log.error("error in getting report: ", error);
    });
  };

  this.$onDestroy = function () {
    xpAlert.clearAlerts();
  };

  function InitReportData(filter) {
    ctrl.inited = false;
    ActiveMode.getReportData(filter).then(function (report) {
      ctrl.reportData = report.data;
      updateReportUI(report.data);
    });
  }

  function alertUnsubscribed(schools) {
    xpAlert.clearAlerts();
    var subscribedResponseCounts = 0;
    var unsubscribedResponseCounts = 0;
    schools.forEach(function(school){
      if (school.is_subscribed) {
        subscribedResponseCounts = subscribedResponseCounts + school.sum_response_count;
      } else {
        unsubscribedResponseCounts = unsubscribedResponseCounts + school.sum_response_count;
      }
    });
    if (unsubscribedResponseCounts) {
      var percentUnsubscribed = Math.round((unsubscribedResponseCounts / (unsubscribedResponseCounts + subscribedResponseCounts)) * 100);
      if (percentUnsubscribed > 20) {
        xpAlert.info(percentUnsubscribed + "% of your data is coming from non-subscription use of Exploros.  To see more specifics, toggle the Data switch to 'unsubscribed'.");
      }
    }

    ctrl.unsubscribedResponses =  {
      labels: ["Subscribed", "Unsubscribed"],
      datasets: [{
        label: "Responses",
        data: [subscribedResponseCounts, unsubscribedResponseCounts],
        fill: false,
        backgroundColor: ["#0090FA", "#6bc0ff"],
        borderWidth: 0
      }
      ]
    };

  }

  function updateReportUI(data) {
    if (ctrl.currentReport === "school-analyzer") {
      ctrl.schools = null;
    } else if (!ctrl.schools || !ctrl.schools.length) {
      ctrl.schools = getSchools(data);
    }
    alertUnsubscribed(data.schools);
    if (!ctrl.teachers || !ctrl.teachers.length) {
      ctrl.teachers = getTeachers(data);
    }
    if (!ctrl.courses || !ctrl.courses.length) {
      ctrl.courses = getCourses(data);
    }
    ctrl.summaryTiles = getSummaryTiles(data);
    ctrl.responsesOverTime = getResponsesOverTime(data);
    ctrl.scoresOverTime = getScoresOverTime(data);
    ctrl.gradesTaught = getGradesTaught(data);
    ctrl.activityColumns = getTeacherActivityColumns();
    ctrl.activityData = getTeacherActivityData(data);
    ctrl.experienceColumns = getExperiencePopularityColumns();
    ctrl.experienceResponses = getExperiencePopularityData(data);
    ctrl.elementResponses = getElementResponseData(data);
    ctrl.inited = true;
  }

  function getTeachers(data, schools) {
    return data.teachers.filter(function(teacher){
      return ((teacher.is_subscribed && ctrl.subscribed) || (!teacher.is_subscribed && !ctrl.subscribed)) &&
              ((!schools) || (schools.indexOf(teacher.school_id) !== -1));
    }).map(function(teacher){
        return {
          teacher_user_id: teacher.teacher_user_id,
          first_name: teacher.teacher_first_name,
          last_name: teacher.teacher_last_name,
          count: teacher.sum_response_count
          };
    }).sort(function(a, b) { return b.count - a.count; });
  }

  function getCourses(data, schools, teachers) {
    return data.collections.filter(function(course){
      return ((course.is_subscribed && ctrl.subscribed) || (!course.is_subscribed && !ctrl.subscribed)) &&
              ((!schools) || (schools.indexOf(course.school_id) !== -1)) &&
              ((!teachers) || (teachers.indexOf(course.teacher_user_id) !== -1));
    }).map(function(course){
    return {
      id: course.top_collection_uuid,
      name: course.top_collection_name,
      count: course.sum_response_count
      };
    }).sort(function(a, b) { return b.count - a.count; });
  }

  ctrl.onRefresh = function(schools, teachers, courses, students, year, subscribed) {
    var changed = false;
    var filter = { year: ctrl.selectedYear.value };

    // if user is changing subscribed data view then just update the report UI
    if (subscribed !== undefined && subscribed !== ctrl.subscribed) {
      ctrl.subscribed = subscribed;
      onSubscribedData();
      return;
    }

    // The first part of this method handles when a school changes.  The API needs to limit the teachers and classes by school
    var schoolIds = schools.map(function(school) { return school.id; });
    if (schoolIds && schoolIds.length && (schoolIds.length != ctrl.selectedSchools.length ||
                                      !schoolIds.every(function(value, index) { return value === ctrl.selectedSchools[index]; }))) {
      ctrl.selectedSchools = schoolIds;
      if (schoolIds[0] !== "All") {
        filter.school_ids = schoolIds;
      }
      ctrl.teachers = [];
      ctrl.courses = [];
      changed = true;
    }

    // This checks a change of the teacher.  This requires updating classes
    var teacherIds = teachers.map(function(teacher) { return teacher.teacher_user_id; });
    if (teacherIds && teacherIds.length && (teacherIds.length != ctrl.selectedTeachers.length ||
        !teacherIds.every(function(value, index) { return value === ctrl.selectedTeachers[index]; }))) {
      ctrl.selectedTeachers = teacherIds;
      if (teacherIds.length > 0 && teacherIds[0] !== "All") {
       filter.teachers = teacherIds;
      }
      ctrl.courses = [];
      changed = true;
    }

    var courseIds = courses.map(function(course) { return course.id; });
    if (courseIds && courseIds.length && (courseIds.length != ctrl.selectedCourses.length ||
        !courseIds.every(function(value, index) { return value === ctrl.selectedCourses[index]; }))) {
      ctrl.selectedCourses = courseIds;
      if (courseIds.length > 0 && courseIds[0] !== "All") {
       filter.collection_uuids = courseIds;
      }
      changed = true;
    }

    if (year && ctrl.selectedYear != year) {
      filter.year = year.value;
      ctrl.selectedYear = year;
      ctrl.schools = [];
      ctrl.teachers = [];
      ctrl.courses = [];
      changed = true;
    }
    if (changed) {
      InitReportData(filter);
    }
  }

  function onSubscribedData() {
    ctrl.schools = [];
    ctrl.teachers = [];
    ctrl.courses = [];
    updateReportUI(ctrl.reportData);
  }

  function getSchools(data) {
    return data.schools.filter(function(school) {
      return (school.is_subscribed && ctrl.subscribed) || (!school.is_subscribed && !ctrl.subscribed);
    }).map(function(sch) {
      return {
        id: sch.school_id,
        name: sch.school_name,
        count: sch.sum_response_count
      };
    }).sort(function(a, b) { return b.count - a.count; });
  }

  function getSummaryTiles(data) {
    var tiles = [
      {
        name: 'Teachers',
        guide: 'taught'
      },
      {
        name: 'Students',
        guide: 'a total of'
      },
      {
        name: 'Experiences',
        guide: 'generating'
      },
      {
        name: 'Responses',
        guide: 'shared'
      },
      {
        name: 'Times',
        guide: false
      }
    ];

    return generateSummaryTiles(
      tiles,
      data
    );
  }

  function generateSummaryTiles(requestedTiles, data) {
    return requestedTiles.map(function (tile) {
      return {
        name: tile.name,
        value: summaryTilesGetters[tile.name](data) === null ? 0 : reportHelperService.abbreviateNumber(summaryTilesGetters[tile.name](data)),
        guide: tile.guide
      };
    });
  }

  var summaryTilesGetters = {
      'Teachers': function(data) { return data.top_summaries[0].teachers_count; },
      'Students': function(data) { return data.summaries[0].students_count; },
      'Experiences': function(data) { return data.top_summaries[0].experiences_count; },
      'Responses': function(data) { return data.top_summaries[0].sum_response_count; },
      'Times': function(data) { return data.summaries[0].shared_sum; }
    };

  function getResponsesOverTime(data) {
    var labels = data.date_data.map(function(response) {
      return window.moment(response.end_date).format('M/DD/YY');
    });
    var values = data.date_data.map(function(response) {
      return response.sum_response_count||0;
    });
    return {
      labels: labels,
      datasets: [{
        label: "Responses",
        data: values,
        fill: false,
        borderColor: "#0090FA"
      }
      ]
    };
  }

  function getScoresOverTime(data) {
    var labels = data.date_data.map(function(response) {
      return window.moment(response.end_date).format('M/DD/YY');
    });
    var totalQuizScores = 0;
    var totalQuizCount = 0;
    var values = data.date_data.map(function(response) {
      if (response.avg_quiz_score !== null && response.avg_quiz_score !== undefined) {
        totalQuizScores = totalQuizScores + response.avg_quiz_score;
        totalQuizCount = totalQuizCount + 1;
      }
      return response.avg_quiz_score||0;
    });
    if (totalQuizCount > 0) {
      var totalAvgOverTime = Math.round(totalQuizScores / totalQuizCount);
      ctrl.quizScoresOverTimeTitle = "Quiz Scores Over Time Averaging " + totalAvgOverTime + "%";
    }
    return {
      labels: labels,
      datasets: [{
        label: "Avg. Score",
        data: values,
        fill: false,
        borderColor: "#0090FA"
      }
      ]
    };
  }

  function nextColor(start, total, current) {
    return Math.round(start + (current * ((256 - start) / total)));
  }

  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function rgbToHex(r,g,b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  function getGradesTaught(data) {
    var labels = data.grades_taught.map(function(response) {
      return response.grade;
    });
    var values = data.grades_taught.map(function(response) {
      return response.experience_count||0;
    });
    var colors = data.grades_taught.map(function(response, index) {
      return rgbToHex(nextColor(0, values.length, index), nextColor(144, values.length, index), nextColor(250, values.length, index)); // "rgb(" + nextColor(0, values.length, index) + "," + nextColor(90, values.length, index) + "," + nextColor(128, values.length, index) + ")"; // "#0090FA";
    });
    return {
      labels: labels,
      datasets: [{
        label: "Experiences Taught",
        data: values,
        fill: false,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        borderWidth: 0
      }
      ]
    };
  }

  function getTeacherActivityColumns() {
    var columns = [];
    columns.push(
        { field: 'teacherName',
          title:'Teacher',
          titleName: 'Teacher',
          sortable:'teacherName',
          class: 'xp-report-small-cell dont-wrap',
          style: '',
        }
      );
    columns.push(
        { field: 'schoolName',
          title:'School',
          titleName: 'School',
          sortable:'schoolName',
          class: 'xp-report-small-cell dont-wrap',
          style: ''
        }
      );
    columns.push(
        { field: 'classCount',
          title:'Classes',
          titleName: 'Classes',
          sortable:'classCount',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    columns.push(
        { field: 'experiences',
          title:'Experiences',
          titleName: 'Experiences',
          sortable:'experiences',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    columns.push(
        { field: 'epc',
          title:'EPC',
          titleName: 'EPC',
          sortable:'epc',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    columns.push(
        { field: 'responses',
          title:'Responses',
          titleName: 'Responses',
          sortable:'responses',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    columns.push(
        { field: 'rps',
          title:'RPS',
          titleName: 'RPS',
          sortable:'rps',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    columns.push(
        { field: 'score',
          title:'Avg. Quiz Score',
          titleName: 'Avg. Quiz Score',
          sortable:'score',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    return columns;
  }

  function getTeacherActivityData(data) {
    var teachers = 0;
    var students = 0;
    var minutes = 0;

    var teacherData = data.teacher_activity.map(function(ta){
      teachers = teachers + 1;
      students = students + ta.students;
      minutes = minutes + ta.sum_time_on_screen_minutes;

      return {
        teacherName: ta.teacher_name,
        schoolName: ta.school_name,
        classCount: reportHelperService.abbreviateNumber(ta.classes_count),
        experiences: reportHelperService.abbreviateNumber(ta.experiences_count),
        epc: reportHelperService.abbreviateNumber(ta.EPC),
        responses: reportHelperService.abbreviateNumber(ta.sum_response_count),
        rps: reportHelperService.abbreviateNumber(ta.RPS),
        score: ta.avg_quiz_score !== null ? (ta.avg_quiz_score + "%") : ""
      };
    });

    ctrl.activityTitle = "TEACHER ACTIVITY - " + teachers + " teacher" + (teachers !== 1 ? "s" : "") + " teaching " + students + " students for " + reportHelperService.abbreviateNumber(minutes) + " student minutes";

    return teacherData;
  }

  function getExperiencePopularityColumns() {
    var columns = [];
    columns.push(
        { field: 'experienceName',
          title:'Experience Name',
          titleName: 'Experience',
          sortable:'experienceName',
          class: 'xp-report-large-cell text-left'
        }
      );
    columns.push(
        { field: 'taught',
          title:'Taught',
          titleName: 'Taught',
          sortable:'taught',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    columns.push(
        { field: 'students',
          title:'Students',
          titleName: 'Students',
          sortable:'students',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    columns.push(
        { field: 'responses',
          title:'Responses',
          titleName: 'Responses',
          sortable:'responses',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    columns.push(
        { field: 'rps',
          title:'RPS',
          titleName: 'RPS',
          sortable:'rps',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    columns.push(
        { field: 'avgQuiz',
          title:'Avg. Quiz Score',
          titleName: 'Avg. Quiz Score',
          sortable:'avgQuiz',
          class: 'xp-report-tiny-cell dont-wrap',
          style: 'text-center'
        }
      );
    return columns;
  }

  function getExperiencePopularityData(data) {
    var experiences = 0;
    var taught = 0;

    var popularityData = data.popularity.map(function(pop){
      experiences = experiences + 1;
      taught = taught + pop.experiences_count;

      return {
        experienceName: pop.experience_name,
        taught: reportHelperService.abbreviateNumber(pop.experiences_count),
        students: reportHelperService.abbreviateNumber(pop.students_count),
        responses: reportHelperService.abbreviateNumber(pop.sum_response_count),
        rps: reportHelperService.abbreviateNumber(pop.RPS),
        avgQuiz: pop.avg_quiz_score !== null ? (pop.avg_quiz_score + "%") : ""
      };
    });

    ctrl.experienceTitle = "LEARNING EXPERIENCE POPULARITY - " + experiences + " different experiences taught " + taught + " times";

    return popularityData;
  }

  function getElementResponseData(data) {
    ctrl.responseTitle = "STUDENT RESPONSES (HIGHER DEPTH OF KNOWLEDGE) - " + data.summaries[0].sum_responses + " responses including " + data.summaries[0].sum_assesment + " assessments";
    return {
      openResponses: data.summaries[0].sum_open_responses,
      graphicOrganizers: data.summaries[0].sum_go,
      tables: data.summaries[0].sum_tables,
      brainstorms: data.summaries[0].sum_brainstorms,
      drawings: data.summaries[0].sum_drawings
    };
  }

  ctrl.downloadPDF = function () {
    reportToPDF.createReportToPDF(ctrl)('printable', 'Analyzer Report', 'scrollingdiv', [1750, 1000], 'landscape');
  };

}

module.component('analyzerReport', {
  require: {parent: '^^xpReport'},
  template: require('./analyzerReport.jade'),
  controller: controller,
});

})();